export const headerOptions = [
  {
    key: '1',
    name: "Náš tím",
    navigateTo: "/pracovnici",
  },
  {
    key: '2',
    name: "Ambulancia",
    navigateTo: "/ambulancia",
  },
  {
    key: '3',
    name: "Galéria",
    navigateTo: "/galeria",
  },
  {
    key: '4',
    name: "Cenník",
    navigateTo: "/cennik",
  },
  {
    key: '5',
    name: "Poučenia",
    navigateTo: "/poucenia",
  },
  {
    key: '6',
    name: "Kalkulačka",
    navigateTo: "/kalkulacka",
  },
  {
    key: '7',
    name: "Kontakt",
    navigateTo: "/kontakt",
  },
]
