import woman from "../../assets/images/woman icon.png"
import ultrasoundScanner from "../../assets/images/ultrasound-icon.png"
import urologyWoman from "../../assets/images/Urology Women.png"
import couple from "../../assets/images/couple-icon.png"

export const introductionCards = [
  {
    key: "1",
    icon: woman,
    altText: "Ikona ženy",
    highStartText: "Komplexnú starostlivosť o ženy",
    text: "diagnostiku a liečbu chorôb ženských pohlavných orgánov, starostlivosť o tehotné ženy s fyziologickou, rizikovou i patologickou tehotnosťou, starostlivosť o ženy po liečbe pre nádorové ochorenia rodidiel.",
    highEndText: "",
  },
  {
    key: "2",
    icon: ultrasoundScanner,
    altText: "Ikona ultrazvuku",
    highStartText: "Ultrazvuk",
    text: "Vykonávanie ultrazvukových (sono) vyšetrení gynekologickým pacientkám, tehotným ženám ako základné skríningové vyšetrenia, tak aj konziliárne vyšetrenia pre iných poskytovateľov zdravotnej starostlivosti, ultrazvukové vyšetrenia na žiadosť pacientky, vykonávanie 3D/4D ultrazvuku. Meranie NT a NB v 11 až 13+6 týždni tehotenstva s výpočtom rizika Downovho syndrómu a iných chromozómových odchýlok podľa metodiky",
    highEndText: " The Fetal Medicine Foundation.",
  },
  {
    key: "3",
    icon: urologyWoman,
    altText: "Ikona urologickej ženy",
    highStartText:
      "Komplexnú starostlivosť o pacientky s poruchami panvového dna a poruchami močenia",
    text: "Diagnostiku porúch panvového dna s návrhom komplexnej liečby od rehabilitačnej až po operačnú, diagnostiku príčin inkontinencie (úniku moču) s komplexnou liečbou od rehabilitačnej liečby, cez medikamentóznu liečbu až po návrh na operačnú liečbu.",
    highEndText: "",
  },
  {
    key: "4",
    icon: couple,
    altText: "Ikona páru",
    highStartText: "Komplexnú starostlivosť o neplodné páry",
    text: "Diagnostiku príčin neplodnosti (sterility), liečbu neplodnosti od hormonálnej liečby až po intrauterinnú insemináciu (IUI), komplexnú prípravu pred odoslaním páru do centra asistovanej reprodukcie, spoluprácu s centrami asistovanej reprodukcie na Slovensku i v Čechách.",
    highEndText: "",
  },
]
