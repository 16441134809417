import Slider from "../../components/Slider"
import { ultrasoundPictures } from "./pictures"
import { TextWrapper, AmbulanceWrapper } from "./StyledComponents"

const Ultrasound = () => (
  <AmbulanceWrapper>
    <TextWrapper>
      <p>
        <strong>Ultrazvuk</strong> (sonografia) je moderná vyšetrovacia metóda,
        ktorá zaznamenala značný rozvoj v posledných rokoch. Princípom je
        zobrazovanie ľudského tela pomocou odrazeného ultrazvukového vlnenia s
        následným počítačovým spracovaním.
      </p>
      <p>
        Dnes je vyšetrenie pomocou ultrazvuku štandardnou súčasťou
        gynekologického vyšetrenia a vyšetrenia v pôrodníctve, a to buď pomocou
        vaginálnej alebo abdominálnej (brušnej) sondy, alebo ich kombináciou.
      </p>
      <p>Slúži na:</p>
      <ul>
        <li>zobrazenie vnútorných ženských pohlavných orgánov</li>
        <li>
          diagnostiku patologických stavov, ako sú napr. vrodené anomálie
          rodidiel, nádory rodidiel a pod.
        </li>
        <li>
          funkčné vyšetrenie pomocou dopplerovského vyšetrenia orgánov malej
          panvy a ich chorobných zmien
        </li>
        <li>diagnostiku mimomaternicového tehotenstva</li>
        <li>
          sledovanie zmien vaječníkov v priebehu cyklu, tzv. folikulometria pri
          liečbe neplodnosti
        </li>
        <li>diagnostiku včasnej tehotnosti</li>
        <li>
          meranie NT a NB ako markerov Downovho syndrómu alebo iných
          chromozómových odchýlok a ďalších ultrazvukových parametrov – prietok
          cez ductus venosus a trikuspidálnu chlopňu s výpočtom rizika
          chromozomálnej aberácie podľa metodiky The Fetal Medicine Foundation
          na základe certifikátu s možnosťou započítania výsledkov biochemických
          vyšetrení I. trimestra
        </li>
        <li>
          skríningové vyšetrenia plodu v 10., 20. a 30. týždni tehotnosti, so
          zameraním na posúdenie morfológie plodu a diagnostiku prípadných
          odchýlok
        </li>
        <li>
          vyšetrenie funkčného stavu plodu, tzv. prietokov v 36. týždni
          tehotenstva
        </li>
        <li>určenie pohlavia plodu</li>
        <li>zobrazenie placenty, pupočníka a plodovej vody</li>
      </ul>
    </TextWrapper>
    <Slider pictures={ultrasoundPictures} height="393px" dots={false} />
  </AmbulanceWrapper>
)

export default Ultrasound
