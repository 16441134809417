import { Collapse } from "antd"
import GynecologyAndObstetrics from "./GynecologyAndObstetrics"
import Ultrasound from "./Ultrasound"
import GynecologicalUrology from "./GynecologicalUrology"
import ReproductiveMedicine from "./ReproductiveMedicine"
import DimensionalUltrasound from "./DimensionalUltrasound"
import HeadLine from "../../components/HeadLine/Headline"
import { CollapseWrapper, StyledPanel } from "./StyledComponents"

const Ambulance = () => (
  <>
    <HeadLine>Naše ambulancie</HeadLine>
    <CollapseWrapper>
      <Collapse
        defaultActiveKey={["1"]}
        bordered={false}
        expandIconPosition="right"
      >
        <StyledPanel header="Gynekológia a pôrodníctvo" key="1">
          <GynecologyAndObstetrics />
        </StyledPanel>
        <StyledPanel header="Ultrazvuk" key="2">
          <Ultrasound />
        </StyledPanel>
        <StyledPanel header="Gynekologická urológia" key="3">
          <GynecologicalUrology />
        </StyledPanel>
        <StyledPanel header="Reprodukčná medicína" key="4">
          <ReproductiveMedicine />
        </StyledPanel>
        <StyledPanel header="3D/4D Ultrazvuk" key="5">
          <DimensionalUltrasound />
        </StyledPanel>
      </Collapse>
    </CollapseWrapper>
  </>
)

export default Ambulance
