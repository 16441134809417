import styled from "styled-components"

const Wrapper = styled.div`
  min-width: 275px;
  height: auto;
  background-color: white;
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  min-width: 280px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 3%;
  border-radius: 10px;
`

const ImageWrapper = styled.div`
  text-align: center;
  margin: auto 20px;
`

const Text = styled.span`
  padding: 5% 5% 5% 0;
  text-size-adjust: auto;
`

const IconWithText = (props) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={props.icon} alt={props.altText} width="96px" />
      </ImageWrapper>
      <Text>
        <strong>{props.highStartText}</strong>
        {" – "}
        {props.text}
        <strong>{props.highEndText}</strong>
      </Text>
    </Wrapper>
  )
}

export default IconWithText
