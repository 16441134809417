import styled from "styled-components"

const StyledLink = styled.a`
    margin: auto 5px;
`

const ImageWithLink = (props) => (
  <StyledLink href={props.link} rel="noreferrer" target="_blank">
    <img src={props.src} alt={props.alt} width="90px" />
  </StyledLink>
)

export default ImageWithLink
