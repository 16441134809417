import DateSelector from "../../components/DateSelector"
import { StyledButton } from "./StyledComponents"

const Period = () => (
  <>
    <DateSelector name="lastPeriodDate" id="lastPeriodDate" />
    <StyledButton type="primary" htmlType="submit">
      Vypočítať
    </StyledButton>
  </>
)

export default Period
