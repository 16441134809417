import InputField from "../../components/InputField"
import DateSelector from "../../components/DateSelector"
import { StyledButton } from "./StyledComponents"

const Ultrasound = () => (
  <>
    <DateSelector name="ultrasoundDate" id="ultrasoundDate" />
    <InputField name="week" placeholder="Týždeň" maxLength={2} />
    <InputField name="day" placeholder="Deň" maxLength={1} />
    <StyledButton type="primary" htmlType="submit">
      Vypočítať
    </StyledButton>
  </>
)

export default Ultrasound
