import { Form, Input, Button } from "antd"
import styled from "styled-components"
import * as route from "../../routes"

const StyledForm = styled(Form)`
  width: 300px;
  margin: 30px auto;
`

const LoginForm = () => {
  const onFinish = (values) => {
    console.log("Success:", values)
    window.location = route.ADMIN
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <StyledForm
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Meno"
        name="username"
        rules={[
          {
            required: true,
            message: "Prosím zadajte prihlasovacie meno.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Heslo"
        name="password"
        rules={[
          {
            required: true,
            message: "Prosím zadajte heslo.",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Prihlásiť
        </Button>
      </Form.Item>
    </StyledForm>
  )
}

export default LoginForm
