export const acts = [
  {
    key: "1",
    name: "Termín pôrodu",
    datesRange: null,
  },
  {
    key: "2",
    name: "Krvný odber PAPP-A",
    datesRange: null,
  },
  {
    key: "3",
    name: "Vyšetrenie NT (šijové prejasnenie)",
    datesRange: null,
  },
  {
    key: "4",
    name: "Krvný odber ALPHA screening",
    datesRange: null,
  },
  {
    key: "5",
    name: "Morfologický ultrazvuk (20. týždeň)",
    datesRange: null,
  },
  // {
  //   key: "6",
  //   name: "3D/4D ultrazvuk",
  //   datesRange: null,
  // },
  {
    key: "7",
    name: "Ultrazvuk (30. týždeň)",
    datesRange: null,
  },
  {
    key: "8",
    name: "Flowmetria",
    datesRange: null,
  },
]

export const daysToAdd = [
  280, 77, 84, 77, 98, 105, 112, 140, 153, 203, 216, 245, 251,
]
