import firstTrimester from "../../assets/documents/I-trimester_n.pdf"
import examinationNt from "../../assets/documents/vysetrenie_nt_n.pdf"
import secondTrimester from "../../assets/documents/II-trimester_n.pdf"
import thirdTrimester from "../../assets/documents/III-trimester_n.pdf"
import flows from "../../assets/documents/prietoky_n.pdf"
import ultrasound34D from "../../assets/documents/3d4d_ultrazvuk_n.pdf"
import gynecologicalUltrasound from "../../assets/documents/gynekologicky_uvz_n.pdf"

export const instructionsList = [
  {
    key: "I. trimester",
    name: "I. trimester",
    file: firstTrimester,
  },
  {
    key: "Vyšetrenie NT",
    name: "Vyšetrenie NT",
    file: examinationNt,
  },
  {
    key: "II. trimester",
    name: "II. trimester",
    file: secondTrimester,
  },
  {
    key: "III. trimester",
    name: "III. trimester",
    file: thirdTrimester,
  },
  {
    key: "Prietoky",
    name: "Prietoky",
    file: flows,
  },
  {
    key: "3D/4D ultrazvuk",
    name: "3D/4D ultrazvuk",
    file: ultrasound34D,
  },
  {
    key: "Gynekologický ultrazvuk", 
    name: "Gynekologický ultrazvuk",
    file: gynecologicalUltrasound,
  },
]
