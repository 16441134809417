import ambulance1 from "../../assets/images/ambulance3D/640/vstup_640.jpg"
import ambulance2 from "../../assets/images/ambulance3D/640/cakaren01_640.jpg"
import ambulance3 from "../../assets/images/ambulance3D/640/cakaren04_640.jpg"
import ambulance4 from "../../assets/images/ambulance3D/640/cakaren05_640.jpg"
import ambulance5 from "../../assets/images/ambulance3D/640/recepcia01_640.jpg"
import ambulance6 from "../../assets/images/ambulance3D/640/recepcia02_640.jpg"
import ambulance7 from "../../assets/images/ambulance3D/640/recepcia03_640.jpg"
import ambulance8 from "../../assets/images/ambulance3D/640/recepcia05_640.jpg"
import ambulance9 from "../../assets/images/ambulance3D/640/ordinacia_640.jpg"
import ambulance10 from "../../assets/images/ambulance3D/640/ordinacia00_640.jpg"
import ambulance11 from "../../assets/images/ambulance3D/640/ordinacia02_640.jpg"
import ambulance12 from "../../assets/images/ambulance3D/640/ordinacia03_640.jpg"

import img3Da from "../../assets/images/images3D/obr1.jpg"
import img3DG from "../../assets/images/images3D/obr31.jpg"
import img3DI from "../../assets/images/images3D/obr33.jpg"

export const pictures = [
  {
    key: "1",
    image: ambulance1,
    alt: "PIC1",
  },
  {
    key: "2",
    image: img3Da,
    alt: "PIC1",
  },
  {
    key: "3",
    image: img3DG,
    alt: "PIC1",
  },
  {
    key: "4",
    image: ambulance2,
    alt: "PIC1",
  },
  {
    key: "5",
    image: img3DI,
    alt: "PIC1",
  },
  {
    key: "6",
    image: ambulance3,
    alt: "PIC1",
  },
  {
    key: "7",
    image: ambulance4,
    alt: "PIC1",
  },
  {
    key: "8",
    image: ambulance5,
    alt: "PIC1",
  },
  {
    key: "9",
    image: ambulance6,
    alt: "PIC1",
  },
  {
    key: "10",
    image: ambulance7,
    alt: "PIC1",
  },
  {
    key: "11",
    image: ambulance8,
    alt: "PIC1",
  },
  {
    key: "12",
    image: ambulance9,
    alt: "PIC1",
  },
  {
    key: "13",
    image: ambulance10,
    alt: "PIC1",
  },
  {
    key: "14",
    image: ambulance11,
    alt: "PIC1",
  },
  {
    key: "15",
    image: ambulance12,
    alt: "PIC1",
  },
]
