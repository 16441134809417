import styled from "styled-components"
import { Button } from "antd"

export const StyledButton = styled(Button)`
  height: 50px;
  width: 200px;
  font-size: 20px;
  margin: -15px 34% 0px 34%;
  @media only screen and (max-width: 1225px) {
    width: 30%;
    height: 50px;
    margin: 10px 35%;
  }
  @media only screen and (max-width: 600px) {
    width: 94%;
    margin: 10px 3%;
  }
`

export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1225px) {
    display: block;
  }
`

export const HeadlineBox = styled.div`
  .smallTable {
    display: none;
  }
  @media only screen and (max-width: 1225px) {
    margin: 10px auto;
  }
  @media only screen and (max-width: 600px) {
    .largeTable {
      display: none;
    }
    .smallTable {
      display: block;
    }
  }
`

export const InfoWrapper = styled.div`
  min-width: 275px;
  height: auto;
  background-color: white;
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.5);
  min-width: 280px;
  margin-top: 25px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 3%;
  border-radius: 10px;
  display: block;

  div:nth-child(2) {
    display: flex;
    justify-content: space-between;
  }

  div:nth-child(2) span {
    margin: 10px 20px;
  }
`

export const ImageWrapper = styled.div`
  text-align: center;
  margin: auto 20px;
  @media only screen and (max-width: 600px) {
    margin: auto 0px;
  }
`

export const Line = styled.hr`
  margin: 5px 0;
`
