import { Collapse } from "antd"
import { StyledNavLink, StyledMobileUl, StyledIcon } from "./StyledComponents"
import { headerOptions } from "./headerOptions"
import { useState } from "react"

const { Panel } = Collapse

const mappedHeaderOptions = headerOptions.map((option) => (
  <li key={option.key}>
    <StyledNavLink to={option.navigateTo}>{option.name}</StyledNavLink>
  </li>
))

const SmallScreenMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const switchMenuVisibility = () => setIsMenuVisible(!isMenuVisible)
  return (
    <Collapse
      activeKey={isMenuVisible && "1"}
      ghost
      expandIconPosition="left"
      expandIcon={({ isActive }) => (
        <StyledIcon
          onClick={switchMenuVisibility}
          rotate={isActive ? 180 : 0}
        />
      )}
    >
      <Panel key="1">
        <StyledMobileUl onClick={switchMenuVisibility}>
          {mappedHeaderOptions}
        </StyledMobileUl>
      </Panel>
    </Collapse>
  )
}

export default SmallScreenMenu
