import Slider from "../../components/Slider"
import { ambulancePictures } from "./pictures"
import { TextWrapper, AmbulanceWrapper } from "./StyledComponents"

const GynecologyAndObstetrics = () => (
  <AmbulanceWrapper>
    <TextWrapper>
      <p>
        Ambulantná starostlivosť v odbore Gynekológia a pôrodníctvo sa zaoberá
        komplexnou starostlivosťou o reprodukčné zdravie ženy v jednotlivých
        obdobiach života, prevenciou, diagnostikou, liečbou a dispenzarizáciou
        chorôb ženských pohlavných orgánov a prsníka, starostlivosťou o tehotnú
        ženu s fyziologickou, rizikovou a patologickou graviditou a
        starostlivosťou o šestonedieľku.
      </p>
      <p>Poskytujeme:</p>
      <ul>
        <li>
          preventívne prehliadky žien, vykonávané v rozsahu stanovenom právnymi
          predpismi, zameranými na prevenciu a včasnú diagnostiku prekanceróz a
          nádorov ženských pohlavných orgánov
        </li>
        <li>
          diagnostiku a liečbu porúch menštruačného cyklu s vyšetrením
          hormonálneho stavu vo všetkých obdobiach života ženy
        </li>
        <li>
          diagnostiku a liečbu zápalových ochorení ženských pohlavných orgánov,
          vrátane sexuálne prenosných infekcií a zápalov prsníkov
        </li>
        <li>
          poradenstvo v oblasti plánovaného rodičovstva (antikoncepcia,
          odoslanie neplodného páru do ambulancie reprodukčnej medicíny)
        </li>
        <li>
          prenatálnu starostlivosť o ženy s fyziologickou, rizikovou a
          patologickou graviditou, so zabezpečením potrebných laboratórnych a
          diagnostických vyšetrení, potrebných pre posúdenie zdravotného stavu
          ženy i stavu a vývoja plodu v maternici, starostlivosť o šestonedieľky
        </li>
        <li>
          vykonávanie malých chirurgických gynekologických výkonov ambulantne
        </li>
        <li>
          vyšetrenia pred interrupciou a diagnostiku a terapiu komplikácií po
          interrupcii
        </li>
      </ul>
      <p>
        Starostlivosť poskytuje kvalifikovaný personál podľa najnovších
        poznatkov lekárskej vedy.
      </p>
    </TextWrapper>
    <Slider pictures={ambulancePictures} height="401px" dots={false}/>
  </AmbulanceWrapper>
)

export default GynecologyAndObstetrics
