import kopcanPhoto from "../../assets/images/team/kopcan_new.jpg"
import hlavacikPhoto from "../../assets/images/team/hlavacik.jpg"
import lintnerPhoto from "../../assets/images/team/lintner.jpg"
import zakovaPhoto from "../../assets/images/team/zakova.jpg"
import exlerovaPhoto from "../../assets/images/team/exlerova.jpg"

export const teammates = [
  {
    key: "kopcan",
    photo: kopcanPhoto,
    altText: "Fotka MUDr. Branislava Kopčana",
    titleBeforeName: "MUDr.",
    name: "Branislav",
    surname: "Kopčan",
    titleAfterName: "",
    positions: [
      "Gynekológ - pôrodník, odborný zástupca",
      "Vedúci lekár oddelenia gynekologickej jednodňovej starostlivosti NsP Ilava",
      "Lekár gynekologicko - pôrodníckej kliniky FN Trenčín",
    ],
    education:
      "Lekárska fakulta Masarykovej Univerzity v Brne Kontinuálne akreditované vzdelávanie v odbore gynekológia a pôrodníctvo, gynekologická urológia",
    certificates: [
      "Špecializácia v odbore Gynekológia a pôrodníctvo I. stupňa",
      "Špecializácia v odbore Gynekológia a pôrodníctvo",
      "Špecializácia v špecializačnom odbore Gynekologická urológia",
    ],
  },
  {
    key: "hlavacik",
    photo: hlavacikPhoto,
    altText: "Fotka MUDr. Martina Hlaváčika",
    titleBeforeName: "MUDr.",
    name: "Martin",
    surname: "Hlaváčik",
    titleAfterName: "",
    positions: [
      "Gynekológ - pôrodník, odborný zástupca",
      "Lekár gynekologicko-pôrodníckej kliniky FN Trenčín",
    ],
    education:
      "Lekárska fakulta Univerzity Komenského v Bratislave Kontinuálne akreditované vzdelávanie v odbore gynekológia a pôrodníctvo, ultrazvuk v gynekológii a pôrodníctve",
    certificates: [
      "Špecializácia v odbore Gynekológia a pôrodníctvo I. stupňa",
      "Špecializácia v odbore Gynekológia a pôrodníctvo",
      "Špecializácia v  odbore Ultrazvuk v gynekológii a pôrodníctve",
      "Certifikát The Fetal Medicine Foundation pre meranie NT, NB a ďalších markerov ultrazvukového skríningu v I. trimestri",
      "Certifikát The Fetal Medicine Foundation pre ultrazvukové vyšetrenie v 20-22 týždni tehotenstva",
    ],
  },
  {
    key: "lintner",
    photo: lintnerPhoto,
    altText: "Fotka MUDr. Rudolfa Lintnera, MBA",
    titleBeforeName: "MUDr.",
    name: "Rudolf",
    surname: "Lintner,",
    titleAfterName: "MBA",
    positions: [
      "Gynekológ - pôrodník, odborný zástupca",
      "Lekár gynekologicko-pôrodníckej kliniky FN Trenčín",
    ],
    education:
      "Lekárska fakulta Univerzity Komenského v Bratislave Kontinuálne akreditované vzdelávanie v odbore gynekológia a pôrodníctvo",
    certificates: [
      "Špecializácia v odbore Gynekológia a pôrodníctvo I. stupňa",
      "Špecializácia v odbore Gynekológia a pôrodníctvo II. stupňa",
    ],
  },
  {
    key: "zakova",
    photo: zakovaPhoto,
    altText: "Fotka Mgr. Martina Hlaváčika",
    titleBeforeName: "Mgr.",
    name: "Iveta",
    surname: "Žáková",
    titleAfterName: "",
    positions: ["Zdravotná sestra"],
    education:
      "Stredná zdravotnícka škola Magistra ošetrovateľstva Kontinuálne akreditované vzdelávanie v odbore gynekológia a pôrodníctvo",
    certificates: ["Certifikát na vykonávanie urodynamických vyšetrení"],
  },
  {
    key: "exlerova",
    photo: exlerovaPhoto,
    altText: "Fotka Bc. Tatiany Exlerovej",
    titleBeforeName: "Bc.",
    name: "Tatiana",
    surname: "Exlerová",
    titleAfterName: "",
    positions: ["Pôrodná asistentka"],
    education:
      "Pôrodná asistencia - nadstavbové štúdium Bakalár - pôrodná asistencia Kontinuálne akreditované vzdelávanie v odbore gynekológia a pôrodníctvo",
    certificates: [],
  },
]
