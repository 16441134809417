import { priceList } from "./prices"

let key = 0

export const mappedPriceList = priceList.map((intervention) => {
  key = key + 1
  return {
    key,
    intervention: intervention.intervention,
    price: `${intervention.price.toFixed(2)}€`,
  }
})

export const columns = [
  {
    title: "Zákrok",
    dataIndex: "intervention",
    key: "intervention",
  },
  {
    title: "Cena",
    dataIndex: "price",
    key: "price",
  },
]