import classes from "./PersonalCard.module.css"

const PersonalCard = (props) => (
  <div className={classes.wrapper}>
    <div className={classes.photo}>
      <img src={props.photo} alt={props.altText} width="200px" />
    </div>
    <div className={classes.description}>
      <h1>
        {props.titleBeforeName} {props.name} {props.surname}{" "}
        {props.titleAfterName}
      </h1>
      <h3>{props.positions}</h3>
    </div>
    <div className={classes.certificates}>
      <h3>Vzdelanie</h3>
      <span>{props.education}</span>
      {props.certificates.length > 0 ? (
        <h3>Špecializácie a certifikáty</h3>
      ) : null}
      {props.certificates}
    </div>
  </div>
)

export default PersonalCard
