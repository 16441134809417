import { Input } from "antd"
import styled from "styled-components"

const StyledInput = styled(Input)`
  width: 100px;
  margin-left: 20px;
  @media only screen and (max-width: 600px) {
    width: 45.5%;
    height: 40px;
    margin: 5px 0% 5px 3%;
  }
`

const InputField = (props) => (
  <StyledInput
    size="large"
    name={props.name}
    placeholder={props.placeholder}
    maxLength={props.maxLength}
  />
)

export default InputField
