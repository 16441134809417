import Slider from "../../components/Slider"
import { uroGynecologyPictures } from "./pictures"
import { TextWrapper, AmbulanceWrapper } from "./StyledComponents"

const GynecologicalUrology = () => (
  <>
    <AmbulanceWrapper>
      <TextWrapper>
        <p>
          Gynekologická urológia (urogynekológia) rieši diagnostiku a
          zabezpečuje liečbu žien s únikom moču (inkontinenciou), poruchou
          funkcie panvového dna a s prolapsom (vypadávaním) rodidiel. Realizuje
          komplexný manažment diagnostiky a liečby inkontinencie moču vo vzťahu
          k ženským rodidlám a konečníku, s následnou medikamentóznou alebo
          chirurgickou liečbou, pokiaľ je indikovaná, alebo ich kombináciou.
        </p>
        <p>
          Komplexná diagnostika pozostáva najmä z využitia klinických
          vyšetrovacích metód a testov, moderných dotazníkových metód,
          vyhodnotením tzv. mikčného denníka poskytujúceho subjektívne i
          objektívne informácie o ťažkostiach s močením a zvykoch u každej
          pacientky. Využíva moderné technické a prístrojové vyšetrovacie metódy
          ako sú ultrazvuk vrátane 3D vyšetrenia panvového dna a urogenitálneho
          hiátu, realizovaním abdominálneho, introitálneho i transvaginálneho
          vyšetrenia, komplexné urodynamické vyšetrenia. Podľa potreby sa
          vykonávajú ďalšie potrebné špecializované vyšetrenia ako CT,
          magnetická rezonancia, cystoskopia a iné.
        </p>
        <p>
          Neoddeliteľnou súčasťou liečby je doporučenie vhodných
          špecializovaných absorpčných pomôcok uľahčujúcich pacientkám normálne
          začlenenie do spoločnosti. Samozrejmosťou je edukácia a špecializované
          poradenstvo v oblasti konzervatívnej liečby a fyzioterapie
          (rehabilitácie) svalov panvového dna.
        </p>
        <p>
          Urogynekológ komplexne diagnostikuje a manažuje liečbu nielen
          inkontinencie a poruchy funkcie dolných močových ciest, ale zároveň
          rieši a lieči i veľmi často združené poruchy panvového dna, poklesu
          pošvových stien a panvových orgánov žien.
        </p>
        <p>
          Inkontinencia moču u žien je veľmi častým problémom, s ktorým žena
          prichádza do ambulancie gynekológa. Problémy s udržaním moču sa môžu
          objaviť v akejkoľvek fáze života ženy. Výskyt tohto ochorenia sa v
          súvislosti s vekom zvyšuje. Okrem dominantnej úlohy poranenia štruktúr
          panvového dna (napr. pôrod alebo ťažká fyzická práca) sa k príčinám
          porúch jeho funkcie radí aj degenerácia svalov, poruchy inervácie,
          zmeny kolagénu a spojiva vplyvom nedostatku hormónov Zároveň nastáva
          degeneratívna denervácia svalov. Inkontinencia moču však nie je
          zvláštnosťou ani u mladších žien. Súčasné poznatky o diagnostike a
          liečbe v urogynekológii umožňujú tieto poruchy efektívne liečiť a
          významne zvýšiť kvalitu života žien.
        </p>
      </TextWrapper>
      <Slider pictures={uroGynecologyPictures} height="450px" dots={false} />
    </AmbulanceWrapper>
  </>
)

export default GynecologicalUrology
