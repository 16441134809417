import Slider from "../../components/Slider"
import { ultrasound3dPictures } from "./pictures"
import { TextWrapper, AmbulanceWrapper } from "./StyledComponents"

const DimensionalUltrasound = () => (
  <AmbulanceWrapper>
    <TextWrapper>
      <p>
        3D ultrazvuk (trojrozmerný ultrazvuk) je nová možnosť ultrazvukového
        zobrazenia, pri ktorej je pomocou série dvojrozmerných (2D) skenov
        rekonštruovaný 3D obraz. Technológia umožňuje zobrazenie tkanív a
        orgánov v troch vzájomne kolmých rovinách a umožňuje zobrazenie rovín,
        ktoré sú v „klasickom“ 2D móde nezobraziteľné.
      </p>
      <p>
        3D/4D ultrazvuk je špecifický spôsob zobrazenia, ktorý pridáva k
        trojrozmernému obrazu ešte ďalšiu dimenziu, ktorou je čas. Predstavuje
        najnovšiu technológiu, pri ktorej rýchlo snímané trojrozmerné obrazy
        umožňujú vyšetrenie plodu v reálnom čase, a tak sledovanie napr. pohybov
        plodu, mimiky tváre. Toto zobrazenie je možné vytvoriť pomocou moderných
        počítačových technológií.
      </p>
      <p>
        Kvalita zobrazenia závisí od akustických podmienok - množstva plodovej
        vody, polohy plodu v maternici, pohybovej aktivity plodu, lokalizácie
        placenty, hrúbky brušnej steny ženy.
      </p>
      <p>
        Získané údaje je možné spracovať aj „off line“, bez prítomnosti
        pacientky. 3D resp. 3D/4D ultrazvuk môže pomôcť pri diagnostike chýb
        vývoja kostí, diagnostike rázštepových chýb, pri vyšetrení srdca a
        diagnostike srdcových chýb a spektrum možností sa neustále rozrastá.
      </p>
      <p>
        3D/4D ultrazvuk nenahrádza „klasický“ 2D ultrazvuk pri diagnostike
        vrodených vývojových chýb, ale ho dopĺňať. V drvivej väčšine prípadov sa
        vykonáva ako tzv. „BABY-FACING“ na prezentáciu plodu formou
        zrozumiteľnou laickej verejnosti.
      </p>
      <p>
        3D/4D ultrazvuk je možné realizovať kedykoľvek v priebehu tehotenstva,
        optimálnym obdobím na dobré zobrazenie plodu, tvz. „BABY – FACING“ je
        24. – 26 . týždeň tehotenstva.
      </p>
    </TextWrapper>
    <Slider pictures={ultrasound3dPictures} height="450px" dots={false}/>
  </AmbulanceWrapper>
)

export default DimensionalUltrasound
