import { Form, Input, DatePicker, Button, message } from "antd"
import emailjs from "emailjs-com"

const Ultrasound = () => {
  const [form] = Form.useForm()
  const dateParams = { year: "numeric", month: "long", day: "numeric" }

  const transformToSlovakDateFormat = (date) =>
    date.toLocaleDateString("sk-SK", dateParams)

  function sendEmail(e) {
    emailjs
      .send(
        "autoContactCegys",
        "template_cegys",
        {
          name: e.name,
          surname: e.surname,
          email: e.email,
          phone: e.phone,
          examination: "Gynekologický ultrazvuk",
          lastPeriod: transformToSlovakDateFormat(new Date(e.lastPeriod)),
          info: e.info,
        },
        "user_eqClql9gUwp6WZtHAyodx"
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log(e.name)
          message.success("Vaša žiadosť o vyšetrenie bola odoslaná!")
          form.resetFields()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      initialValues={{ remember: true }}
      onFinish={sendEmail}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Titul">
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Meno"
        name="name"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoje meno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Priezvisko"
        name="surname"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoje priezvisko.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoj e-mail.",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item label="Telefón" name="phone">
        <Input type="tel" />
      </Form.Item>
      <Form.Item
        label="Posledná menštruácia"
        name="lastPeriod"
        rules={[
          {
            required: false,
            message: "Zabudli ste zadať dátum poslednej menštruácie.",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item label="Pripomienky:" name="info">
        <Input type="text" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Odoslať
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Ultrasound
