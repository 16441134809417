const ReproductiveMedicine = () => (
  <>
    <p>
      Reprodukčná medicína je medicínsky odbor, ktorý sa zaoberá fyziológiou a
      odchýlkami reprodukčného systému človeka, so zameraním na prevenciu,
      diagnostiku a liečbu porúch a ochorení reprodukčného systému ženy, s
      cieľom zabezpečiť alebo obnoviť jej plodnosť (fertilitu). Pri plnení úloh
      je nevyhnutná spolupráca gynekológa so špecialistami iných medicínskych
      odborov – endokrinológa, sexuológa, andrológa, klinického genetika.
    </p>
    <p>
      Neplodnosť (sterilita) je definovaná ako neschopnosť otehotnieť v priebehu
      jedného roka pri nechránenom pohlavnom styku. Príčiny sterility môžu byť
      na strane ženy, aj na strane muža, alebo ich kombinácia.
    </p>
    <p>
      Diagnostika príčin sterility musí byť komplexná a týka sa oboch partnerov
      túžiacich po potomkovi. Postupuje sa od najjednoduchších postupov,
      odhaliacich najčastejšie príčiny neplodnosti, ako je vyšetrenie
      hormonálneho profilu, analýza menštruačného cyklu a jeho porúch,
      ultrazvukové sledovanie rastu folikulov – tzv. folikulometria, 3D
      sonografia pri diagnostike niektorých vývojových chýb rodidiel, vyšetrenie
      spermiogramu, k náročnejším ako je hysteroskopia, laparoskopia a
      chromopertubácia, teda operačné výkony slúžiace na odhalenie odchýlok
      vnútorných rodidiel a priechodnosti vajcovodov. I tak sa v niektorých
      prípadoch nepodarí odhaliť príčinu sterility na súčasnej úrovni poznania.
    </p>
    <p>
      Liečba neplodnosti je tiež komplexná, podľa zistenej príčiny, opäť od
      jednoduchších postupov, najmenej zaťažujúcich a stresujúcich partnerov až
      po náročnejšie výkony metódami asistovanej reprodukcie. Spolupracujeme s
      centrami asistovanej reprodukcie na Slovensku i v Čechách.
    </p>
  </>
)

export default ReproductiveMedicine
