export const scheduleList = [
  {
    day: "Pondelok",
    ambulance: "Gyn.Ambulancia/Ultrazvuk/Urogynekológia",
    schedule1: "7:00 - 12:00",
    schedule2: "12:30 - 19:00",
  },
  {
    day: "Utorok",
    ambulance: "Gyn.Ambulancia/Urogynekológia",
    schedule1: "7:00 - 12:00",
    schedule2: "12:30 - 17:00",
  },
  {
    day: "Streda",
    ambulance: "Gyn.Ambulancia/Ultrazvuk/Urogynekológia",
    schedule1: "7:00 - 12:00",
    schedule2: "12:30 - 15:00",
  },
  {
    day: "Štvrtok",
    ambulance: "Gyn.Ambulancia/Ultrazvuk/Urogynekológia",
    schedule1: "7:00 - 12:00",
    schedule2: "12:30 - 15:00",
  },
  {
    day: "Piatok",
    ambulance: "Gyn.Ambulancia/Urogynekológia",
    schedule1: "7:00 - 12:00",
    schedule2: "12:30 - 15:00",
  },
]

export const smallScheduleList = [
  {
    day: "Pon.",
    ambulance: "Gyn.Ambulancia/ Ultrazvuk/ Gyn.Urológia",
    schedule: "7:30-12:00 12:30-19:00",
  },
  {
    day: "Ut.",
    ambulance: "Gyn.Ambulancia/ Gyn.Urológia",
    schedule: "7:30-12:00 12:30-17:00",
  },
  {
    day: "Str.",
    ambulance: "Gyn.Ambulancia/ Ultrazvuk/ Gyn.Urológia",
    schedule: "7:30-12:00 12:30-15:00",
  },
  {
    day: "Štv.",
    ambulance: "Gyn.Ambulancia/ Ultrazvuk/ Gyn.Urológia",
    schedule: "7:30-12:00 12:30-15:00",
 },
  {
    day: "Pia.",
    ambulance: "Gyn.Ambulancia/ Gyn.Urológia",
    schedule: "7:30-12:00 12:30-15:00",
  },
]
