export const texts = [
  <>
    Starostlivosť poskytuje vysokokvalifikovaný personál na moderných
    prístrojoch podľa aktuálnych poznatkov lekárskej vedy.
  </>,
  <>
    Naša spoločnosť,{" "}
    <strong>CeGyS, s.r.o., Centrum Gynekologickej Starostlivosti</strong>{" "}
    ponúka:
  </>,
]
