// import building1 from "../../assets/images/bulding/20211005_164346.jpg"
// import building2 from "../../assets/images/bulding/20211005_165707.jpg"
// import building3 from "../../assets/images/bulding/20211005_165710.jpg"
// import building4 from "../../assets/images/bulding/20211005_165820.jpg"

import ambulance1 from "../../assets/images/ambulance3D/1024/vstup_1024.jpg"
import ambulance2 from "../../assets/images/ambulance3D/1024/cakaren01_1024.jpg"
import ambulance3 from "../../assets/images/ambulance3D/1024/cakaren04_1024.jpg"
import ambulance4 from "../../assets/images/ambulance3D/1024/cakaren05_1024.jpg"
import ambulance5 from "../../assets/images/ambulance3D/1024/recepcia01_1024.jpg"
import ambulance6 from "../../assets/images/ambulance3D/1024/recepcia02_1024.jpg"
import ambulance7 from "../../assets/images/ambulance3D/1024/recepcia03_1024.jpg"
import ambulance8 from "../../assets/images/ambulance3D/1024/recepcia05_1024.jpg"
import ambulance9 from "../../assets/images/ambulance3D/1024/ordinacia_1024.jpg"
import ambulance10 from "../../assets/images/ambulance3D/1024/ordinacia00_1024.jpg"
import ambulance11 from "../../assets/images/ambulance3D/1024/ordinacia02_1024.jpg"
import ambulance12 from "../../assets/images/ambulance3D/1024/ordinacia03_1024.jpg"

import ambulance1_mini from "../../assets/images/ambulance3D/320/vstup_320.jpg"
import ambulance2_mini from "../../assets/images/ambulance3D/320/cakaren01_320.jpg"
import ambulance3_mini from "../../assets/images/ambulance3D/320/cakaren04_320.jpg"
import ambulance4_mini from "../../assets/images/ambulance3D/320/cakaren05_320.jpg"
import ambulance5_mini from "../../assets/images/ambulance3D/320/recepcia01_320.jpg"
import ambulance6_mini from "../../assets/images/ambulance3D/320/recepcia02_320.jpg"
import ambulance7_mini from "../../assets/images/ambulance3D/320/recepcia03_320.jpg"
import ambulance8_mini from "../../assets/images/ambulance3D/320/recepcia05_320.jpg"
import ambulance9_mini from "../../assets/images/ambulance3D/320/ordinacia_320.jpg"
import ambulance10_mini from "../../assets/images/ambulance3D/320/ordinacia00_320.jpg"
import ambulance11_mini from "../../assets/images/ambulance3D/320/ordinacia02_320.jpg"
import ambulance12_mini from "../../assets/images/ambulance3D/320/ordinacia03_320.jpg"

import img2Da from "../../assets/images/images2D/01a.jpg"
import img2Db from "../../assets/images/images2D/01b.jpg"
import img2Dc from "../../assets/images/images2D/01c.jpg"
import img2Dd from "../../assets/images/images2D/01d.jpg"
import img2De from "../../assets/images/images2D/01e.jpg"
import img2Df from "../../assets/images/images2D/02.jpg"
import img2Dg from "../../assets/images/images2D/03.jpg"
import img2Dh from "../../assets/images/images2D/04.jpg"
import img2Di from "../../assets/images/images2D/05.jpg"
import img2Dj from "../../assets/images/images2D/06.jpg"
import img2Dk from "../../assets/images/images2D/07.jpg"
import img2Dl from "../../assets/images/images2D/08.jpg"
import img2Dm from "../../assets/images/images2D/09.jpg"
import img2Dn from "../../assets/images/images2D/10.jpg"
import img2Do from "../../assets/images/images2D/11.jpg"
import img2Dp from "../../assets/images/images2D/12.jpg"
import img2Dr from "../../assets/images/images2D/13.jpg"
import img2Ds from "../../assets/images/images2D/14.jpg"
import img2Dt from "../../assets/images/images2D/15.jpg"
import img2Du from "../../assets/images/images2D/16.jpg"
import img2Dv from "../../assets/images/images2D/17.jpg"
import img2Dw from "../../assets/images/images2D/18.jpg"

import img2Da_mini from "../../assets/images/images2D/320/01a_320.jpg"
import img2Db_mini from "../../assets/images/images2D/320/01b_320.jpg"
import img2Dc_mini from "../../assets/images/images2D/320/01c_320.jpg"
import img2Dd_mini from "../../assets/images/images2D/320/01d_320.jpg"
import img2De_mini from "../../assets/images/images2D/320/01e_320.jpg"
import img2Df_mini from "../../assets/images/images2D/320/02_320.jpg"
import img2Dg_mini from "../../assets/images/images2D/320/03_320.jpg"
import img2Dh_mini from "../../assets/images/images2D/320/04_320.jpg"
import img2Di_mini from "../../assets/images/images2D/320/05_320.jpg"
import img2Dj_mini from "../../assets/images/images2D/320/06_320.jpg"
import img2Dk_mini from "../../assets/images/images2D/320/07_320.jpg"
import img2Dl_mini from "../../assets/images/images2D/320/08_320.jpg"
import img2Dm_mini from "../../assets/images/images2D/320/09_320.jpg"
import img2Dn_mini from "../../assets/images/images2D/320/10_320.jpg"
import img2Do_mini from "../../assets/images/images2D/320/11_320.jpg"
import img2Dp_mini from "../../assets/images/images2D/320/12_320.jpg"
import img2Dr_mini from "../../assets/images/images2D/320/13_320.jpg"
import img2Ds_mini from "../../assets/images/images2D/320/14_320.jpg"
import img2Dt_mini from "../../assets/images/images2D/320/15_320.jpg"
import img2Du_mini from "../../assets/images/images2D/320/16_320.jpg"
import img2Dv_mini from "../../assets/images/images2D/320/17_320.jpg"
import img2Dw_mini from "../../assets/images/images2D/320/18_320.jpg"

import img3Da from "../../assets/images/images3D/obr1.jpg"
import img3Dd from "../../assets/images/images3D/obr3.jpg"
import img3De from "../../assets/images/images3D/obr4.jpg"
import img3Df from "../../assets/images/images3D/obr5.jpg"
import img3Dg from "../../assets/images/images3D/obr6.jpg"
import img3Dh from "../../assets/images/images3D/obr7.jpg"
import img3Di from "../../assets/images/images3D/obr8.jpg"
import img3Dj from "../../assets/images/images3D/obr9.jpg"
import img3Dk from "../../assets/images/images3D/obr10.jpg"
import img3Dl from "../../assets/images/images3D/obr11.jpg"
import img3Dm from "../../assets/images/images3D/obr12.jpg"
import img3Dn from "../../assets/images/images3D/obr13.jpg"
import img3Do from "../../assets/images/images3D/obr14.jpg"
import img3Dp from "../../assets/images/images3D/obr15.jpg"
import img3Dr from "../../assets/images/images3D/obr16.jpg"
import img3Ds from "../../assets/images/images3D/obr17.jpg"
import img3Dt from "../../assets/images/images3D/obr18.jpg"
import img3Du from "../../assets/images/images3D/obr19.jpg"
import img3Dv from "../../assets/images/images3D/obr20.jpg"
import img3Dx from "../../assets/images/images3D/obr22.jpg"
import img3Dy from "../../assets/images/images3D/obr23.jpg"
import img3Dz from "../../assets/images/images3D/obr24.jpg"
import img3DA from "../../assets/images/images3D/obr25.jpg"
import img3DB from "../../assets/images/images3D/obr26.jpg"
import img3DC from "../../assets/images/images3D/obr27.jpg"
import img3DD from "../../assets/images/images3D/obr28.jpg"
import img3DE from "../../assets/images/images3D/obr29.jpg"
import img3DF from "../../assets/images/images3D/obr30.jpg"
import img3DG from "../../assets/images/images3D/obr31.jpg"
import img3DH from "../../assets/images/images3D/obr32.jpg"
import img3DI from "../../assets/images/images3D/obr33.jpg"

import gif1 from "../../assets/images/video 3D/video1.gif"
import gif2 from "../../assets/images/video 3D/video2.gif"
import gif3 from "../../assets/images/video 3D/video3.gif"
import gif4 from "../../assets/images/video 3D/video4.gif"
import gif5 from "../../assets/images/video 3D/video5.gif"
import gif6 from "../../assets/images/video 3D/video6.gif"
import gif7 from "../../assets/images/video 3D/video7.gif"
import gif8 from "../../assets/images/video 3D/video8.gif"
import gif9 from "../../assets/images/video 3D/video9.gif"
import gif10 from "../../assets/images/video 3D/video10.gif"
import gif11 from "../../assets/images/video 3D/video11.gif"
import gif12 from "../../assets/images/video 3D/video12.gif"
import gif13 from "../../assets/images/video 3D/video13.gif"

import gif1_mini from "../../assets/images/video 3D/320/video1_320.jpg"
import gif2_mini from "../../assets/images/video 3D/320/video2_320.jpg"
import gif3_mini from "../../assets/images/video 3D/320/video3_320.jpg"
import gif4_mini from "../../assets/images/video 3D/320/video4_320.jpg"
import gif5_mini from "../../assets/images/video 3D/320/video5_320.jpg"
import gif6_mini from "../../assets/images/video 3D/320/video6_320.jpg"
import gif7_mini from "../../assets/images/video 3D/320/video7_320.jpg"
import gif8_mini from "../../assets/images/video 3D/320/video8_320.jpg"
import gif9_mini from "../../assets/images/video 3D/320/video9_320.jpg"
import gif10_mini from "../../assets/images/video 3D/320/video10_320.jpg"
import gif11_mini from "../../assets/images/video 3D/320/video11_320.jpg"
import gif12_mini from "../../assets/images/video 3D/320/video12_320.jpg"
import gif13_mini from "../../assets/images/video 3D/320/video13_320.jpg"

export const twoDimensional = [
  {
    key: "0",
    image: img2Da,
    alt: "PIC1",
    mini: img2Da_mini,
  },
  {
    key: "1",
    image: img2Db,
    alt: "PIC1",
    mini: img2Db_mini,
  },
  {
    key: "2",
    image: img2Dc,
    alt: "PIC1",
    mini: img2Dc_mini,
  },
  {
    key: "3",
    image: img2Dd,
    alt: "PIC1",
    mini: img2Dd_mini,
  },
  {
    key: "4",
    image: img2De,
    alt: "PIC1",
    mini: img2De_mini,
  },
  {
    key: "5",
    image: img2Df,
    alt: "PIC1",
    mini: img2Df_mini,
  },
  {
    key: "6",
    image: img2Dg,
    alt: "PIC1",
    mini: img2Dg_mini,
  },
  {
    key: "7",
    image: img2Dh,
    alt: "PIC1",
    mini: img2Dh_mini,
  },
  {
    key: "8",
    image: img2Di,
    alt: "PIC1",
    mini: img2Di_mini,
  },
  {
    key: "9",
    image: img2Dj,
    alt: "PIC1",
    mini: img2Dj_mini,
  },
  {
    key: "10",
    image: img2Dk,
    alt: "PIC1",
    mini: img2Dk_mini,
  },
  {
    key: "11",
    image: img2Dl,
    alt: "PIC1",
    mini: img2Dl_mini,
  },
  {
    key: "12",
    image: img2Dm,
    alt: "PIC1",
    mini: img2Dm_mini,
  },
  {
    key: "13",
    image: img2Dn,
    alt: "PIC1",
    mini: img2Dn_mini,
  },
  {
    key: "14",
    image: img2Do,
    alt: "PIC1",
    mini: img2Do_mini,
  },
  {
    key: "15",
    image: img2Dp,
    alt: "PIC1",
    mini: img2Dp_mini,
  },
  {
    key: "16",
    image: img2Dr,
    alt: "PIC1",
    mini: img2Dr_mini,
  },
  {
    key: "17",
    image: img2Ds,
    alt: "PIC1",
    mini: img2Ds_mini,
  },
  {
    key: "18",
    image: img2Dt,
    alt: "PIC1",
    mini: img2Dt_mini,
  },
  {
    key: "19",
    image: img2Du,
    alt: "PIC1",
    mini: img2Du_mini,
  },
  {
    key: "20",
    image: img2Dv,
    alt: "PIC1",
    mini: img2Dv_mini,
  },
  {
    key: "21",
    image: img2Dw,
    alt: "PIC1",
    mini: img2Dw_mini,
  },
]

export const threeDimensional = [
  {
    key: "0",
    image: img3Da,
    alt: "PIC1",
  },
  {
    key: "1",
    image: img3Dd,
    alt: "PIC1",
  },
  {
    key: "2",
    image: img3De,
    alt: "PIC1",
  },
  {
    key: "3",
    image: img3Df,
    alt: "PIC1",
  },
  {
    key: "4",
    image: img3Dg,
    alt: "PIC1",
  },
  {
    key: "5",
    image: img3Dh,
    alt: "PIC1",
  },
  {
    key: "6",
    image: img3Di,
    alt: "PIC1",
  },
  {
    key: "7",
    image: img3Dj,
    alt: "PIC1",
  },
  {
    key: "8",
    image: img3Dk,
    alt: "PIC1",
  },
  {
    key: "9",
    image: img3Dl,
    alt: "PIC1",
  },
  {
    key: "10",
    image: img3Dm,
    alt: "PIC1",
  },
  {
    key: "11",
    image: img3Dn,
    alt: "PIC1",
  },
  {
    key: "12",
    image: img3Do,
    alt: "PIC1",
  },
  {
    key: "13",
    image: img3Dp,
    alt: "PIC1",
  },
  {
    key: "14",
    image: img3Dr,
    alt: "PIC1",
  },
  {
    key: "15",
    image: img3Ds,
    alt: "PIC1",
  },
  {
    key: "16",
    image: img3Dt,
    alt: "PIC1",
  },
  {
    key: "17",
    image: img3Du,
    alt: "PIC1",
  },
  {
    key: "18",
    image: img3Dv,
    alt: "PIC1",
  },
  {
    key: "19",
    image: img3Dx,
    alt: "PIC1",
  },
  {
    key: "20",
    image: img3Dy,
    alt: "PIC1",
  },
  {
    key: "21",
    image: img3Dz,
    alt: "PIC1",
  },
  {
    key: "22",
    image: img3DA,
    alt: "PIC1",
  },
  {
    key: "23",
    image: img3DB,
    alt: "PIC1",
  },
  {
    key: "24",
    image: img3DC,
    alt: "PIC1",
  },
  {
    key: "25",
    image: img3DD,
    alt: "PIC1",
  },
  {
    key: "26",
    image: img3DE,
    alt: "PIC1",
  },
  {
    key: "27",
    image: img3DF,
    alt: "PIC1",
  },
  {
    key: "28",
    image: img3DG,
    alt: "PIC1",
  },
  {
    key: "29",
    image: img3DH,
    alt: "PIC1",
  },
  {
    key: "30",
    image: img3DI,
    alt: "PIC1",
  },
]

export const fourDimensional = [
  {
    key: "0",
    image: gif1,
    alt: "PIC1",
    mini: gif1_mini,
  },
  {
    key: "1",
    image: gif2,
    alt: "PIC1",
    mini: gif2_mini,
  },
  {
    key: "2",
    image: gif3,
    alt: "PIC1",
    mini: gif3_mini,
  },
  {
    key: "3",
    image: gif4,
    alt: "PIC1",
    mini: gif4_mini,
  },
  {
    key: "4",
    image: gif5,
    alt: "PIC1",
    mini: gif5_mini,
  },
  {
    key: "5",
    image: gif6,
    alt: "PIC1",
    mini: gif6_mini,
  },
  {
    key: "6",
    image: gif7,
    alt: "PIC1",
    mini: gif7_mini,
  },
  {
    key: "7",
    image: gif8,
    alt: "PIC1",
    mini: gif8_mini,
  },
  {
    key: "8",
    image: gif9,
    alt: "PIC1",
    mini: gif9_mini,
  },
  {
    key: "9",
    image: gif10,
    alt: "PIC1",
    mini: gif10_mini,
  },
  {
    key: "10",
    image: gif11,
    alt: "PIC1",
    mini: gif11_mini,
  },
  {
    key: "11",
    image: gif12,
    alt: "PIC1",
    mini: gif12_mini,
  },
  {
    key: "12",
    image: gif13,
    alt: "PIC1",
    mini: gif13_mini,
  },
]

export const ambulance = [
  // {
  //   key: "0",
  //   image: building1,
  //   alt: "PIC1",
  // },
  // {
  //   key: "1",
  //   image: building2,
  //   alt: "PIC1",
  // },
  // {
  //   key: "2",
  //   image: building3,
  //   alt: "PIC1",
  // },
  // {
  //   key: "3",
  //   image: building4,
  //   alt: "PIC1",
  // },
  {
    key: "4",
    image: ambulance1,
    alt: "PIC1",
    mini: ambulance1_mini,
  },
  {
    key: "5",
    image: ambulance2,
    alt: "PIC1",
    mini: ambulance2_mini,
  },
  {
    key: "6",
    image: ambulance3,
    alt: "PIC1",
    mini: ambulance3_mini,
  },
  {
    key: "7",
    image: ambulance4,
    alt: "PIC1",
    mini: ambulance4_mini,
  },
  {
    key: "8",
    image: ambulance5,
    alt: "PIC1",
    mini: ambulance5_mini,
  },
  {
    key: "9",
    image: ambulance6,
    alt: "PIC1",
    mini: ambulance6_mini,
  },
  {
    key: "10",
    image: ambulance7,
    alt: "PIC1",
    mini: ambulance7_mini,
  },
  {
    key: "11",
    image: ambulance8,
    alt: "PIC1",
    mini: ambulance8_mini,
  },
  {
    key: "12",
    image: ambulance9,
    alt: "PIC1",
    mini: ambulance9_mini,
  },
  {
    key: "13",
    image: ambulance10,
    alt: "PIC1",
    mini: ambulance10_mini,
  },
  {
    key: "14",
    image: ambulance11,
    alt: "PIC1",
    mini: ambulance11_mini,
  },
  {
    key: "15",
    image: ambulance12,
    alt: "PIC1",
    mini: ambulance12_mini,
  },
]
