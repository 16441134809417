import { scheduleList, smallScheduleList } from "./scheduleList"

let key = 0

export const mappedScheduleList = scheduleList.map((item) => {
  key = key + 1
  return {
    key,
    day: item.day,
    ambulance: item.ambulance,
    schedule1: item.schedule1,
    schedule2: item.schedule2,
  }
})

export const mappedSmallScheduleList = smallScheduleList.map((item) => {
  key = key + 1
  return {
    key,
    day: item.day,
    ambulance: item.ambulance,
    schedule: item.schedule,
  }
})

export const columns = [
  {
    title: "Deň",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "Ambulancia",
    dataIndex: "ambulance",
    key: "ambulance",
  },
  {
    title: "Čas 1",
    dataIndex: "schedule1",
    key: "schedule1",
  },
  {
    title: "Čas 2",
    dataIndex: "schedule2",
    key: "schedule2",
  },
]

export const smallColumns = [
  {
    title: "Deň",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "Ambulancia",
    dataIndex: "ambulance",
    key: "ambulance",
  },
  {
    title: "Čas",
    dataIndex: "schedule",
    key: "schedule",
  },
]

export const tableHeaderHandler = () => "Ordinačné hodiny"
