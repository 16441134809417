import vszp from "../../assets/images/vszp_logo.png"
import dovera from "../../assets/images/dovera_logo.png"
import union from "../../assets/images/union_logo.png"
import ImageWithLink from "../ImageWithLink"
import styled from "styled-components"
import Modal from "../../components/Modal"
import { Button } from "antd"
import { useState } from "react"

const vszpAlternativeText = "Logo všeobecnej zdravotnej poisťovne"
const doveraAlternativeText = "Logo poisťovne Dôvera"
const unionAlternativeText = "Logo poisťovne Union"

const vszpWeb = "https://www.vszp.sk/"
const doveraWeb = "https://www.dovera.sk/"
const unionWeb = "https://www.union.sk/zdravotna-poistovna/"

const StyledButton = styled(Button)`
  height: 50px;
  width: 200px;
  font-size: 20px;
  margin: 20px auto;
  @media only screen and (max-width: 1225px) {
    width: 30%;
    height: 50px;
    margin: 10px 35%;
  }
  @media only screen and (max-width: 600px) {
    width: 94%;
    margin: 10px 3%;
  }
`

const MainWrapper = styled.div`
  text-align: center;
`

const Wrapper = styled.div`
  width: 280px;
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
`

const InsuranceCompanies = (props) => {
  const [visible, setVisible] = useState(false)
  return (
    <MainWrapper style={props.isFooter ? { color: "#cccccc" } : null}>
      {props.isFooter ? null : (
        <>
          <StyledButton type="primary" onClick={() => setVisible(true)}>
            Objednať sa
          </StyledButton>
          <Modal visible={visible} onCancel={() => setVisible(false)} />
        </>
      )}
      <Wrapper>
        <ImageWithLink link={vszpWeb} src={vszp} alt={vszpAlternativeText} />
        <ImageWithLink
          link={doveraWeb}
          src={dovera}
          alt={doveraAlternativeText}
        />
        <ImageWithLink link={unionWeb} src={union} alt={unionAlternativeText} />
      </Wrapper>
      <p>
        Sme zmluvným zdravotníckym zariademím poisťovní: VšZP, Dôvera a Union.
      </p>
    </MainWrapper>
  )
}

export default InsuranceCompanies
