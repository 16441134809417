export const columns = [
  {
    title: "Úkon",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Dátumy (od - do)",
    dataIndex: "datesRange",
    key: "datesRange",
  },
]
