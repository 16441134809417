export const priceList = [
  {
    intervention: "Doplatok pri sonografickom vyšetrení II. trimester",
    price: 10,
  },
  {
    intervention:
      "Doplatok pri sonografickom vyšetrení I. alebo III. trimester",
    price: 5,
  },
  {
    intervention:
      "Doplatok pri sonografickom vyšetrení šijového prejasnenia plodu (NT)",
    price: 10,
  },
  {
    intervention:
      "Doplatok pri sonografickom vyšetrení - gynekologický ultrazvuk",
    price: 5,
  },
  {
    intervention: "Fotka zo sonografického vyšetrenia 2D ",
    price: 3,
  },
  {
    intervention: "Fotka zo sonografického vyšetrenia 2D (2-3 fotky)",
    price: 5,
  },
  {
    intervention: "Fotka zo sonografického vyšetrenia 3D",
    price: 6,
  },
  {
    intervention: "Fotka zo sonografického vyšetrenia 3D (2-3 fotky)",
    price: 10,
  },
  {
    intervention:
      "Sonografické vyšetrenie na žiadosť pacientky bez zdravotnej indikácie - gynekologický ultrazvuk",
    price: 25,
  },
  {
    intervention:
      "Sonografické vyšetrenie na žiadosť pacientky - NT s vyhodnotením rizika certifikovaným softvérom",
    price: 50,
  },
  {
    intervention:
      "Sonografické vyšetrenie na žiadosť pacientky - I. alebo III. trimester",
    price: 25,
  },
  {
    intervention:
      "Sonografické vyšetrenie na žiadosť pacientky - II. trimester",
    price: 50,
  },
  {
    intervention:
      "Morfologický ultrazvuk v II. trimestri s využitím multiplanárneho (3D) zobrazenia - doplatok za výkon nehradený zo zdravotného poistenia",
    price: 15,
  },
  {
    intervention:
      "3D/4D ultrazvuk so záznamom najkvalitnejších sekvencií na CD/DVD, resp. USB",
    price: 50,
  },
  {
    intervention:
      "3D/4D sekvencie so záznamom pamäťové médium (2 videosekvencie)",
    price: 15,
  },
  {
    intervention: "3D zobrazenie panvového dna",
    price: 35,
  },
  {
    intervention:
      "Urodynamická štúdia – komplexná služba vrátane nehradeného jednorazového zdravotného materiálu zdravotnou poisťovňou",
    price: 25,
  },
  {
    intervention:
      "Urogynekologické vyšetrenie a klasifikácia POP na žiadosť pacienta vrátane UZV, potrebných testov a dotazníkov podľa štandardov a doporučení  odborných spoločností  IUGA a ISC",
    price: 50,
  },
  {
    intervention:
      "Vyhodnotenie dotazníkov doporučených IUGA a ISC pri POP a inkontinencii, nehradených zdravotnými poisťovňami",
    price: 2,
  },
  {
    intervention:
      "Vyšetrenie nepoistenej/neregistrovanej pacientky vrátane UZV ak sa nejedná o neodkladnú zdravotnú starostlivosť",
    price: 50,
  },
  {
    intervention:
      "PAD weight test – meranie závažnosti inkontinencie moču",
    price: 5,
  },
  {
    intervention:
      "Konzultácia a poradenská činnosť pre inkontinenciu s doporučením individuálne vhodnej absorbčnej pomôcky podľa stupňa inkontinencie",
    price: 10,
  },
  {
    intervention:
      "Doplatok za vyšetrenie mimo ordinačných hodnín, v deň pracovného pokoja alebo sviatok na žiadosť pacientky",
    price: 30,
  },
  {
    intervention: "Konzultácia a poradenstvo podľa dĺžky trvania",
    price: 10,
  },
  {
    intervention: "Konzultácia a poradenstvo podľa dĺžky trvania",
    price: 20,
  },
  {
    intervention: "Konzultácia a poradenstvo podľa dĺžky trvania",
    price: 30,
  },
  {
    intervention: "Elektromagnetická stimulácia 1 svalová skupina, jedna aplikácia",
    price: 35,
  },
  {
    intervention: "Elektromagnetická stimulácia 2 svalové skupiny, jedna aplikácia",
    price: 45,
  },
  {
    intervention: "Elektromagnetická stimulácia 1 svalová skupina, 6 aplikácií",
    price: 175,
  },
  {
    intervention: "Elektromagnetická stimulácia 2 svalové skupiny, 6 aplikácií",
    price: 225,
  },
  {
    intervention: "Vyšetrenie jednorazovým plastovým sterilným vyšetrovacím zrkadlom",
    price: 1,
  },
  {
    intervention: "Cytologický ster LBC - doplatok za nehradený materiál (okrem ZP Union vrámci prevencie)",
    price: 25,
  },
  {
    intervention:
      "Odber kultivácie na chlamýdie alebo mykoplazmy - doplatok za nehradený špeciálny zdravotný materiál",
    price: 5,
  },
  {
    intervention: "Odber biologického materiálu na žiadosť pacienta (za 1 set)",
    price: 2,
  },
  {
    intervention:
      "Vyšetrenie rizikových typov HPV infekcii nad rámec zdravotného poistenia",
    price: 60,
  },
  {
    intervention:
      "Vyšetrenie všetkých typov HPV infekcii nad rámec zdravotného poistenia ",
    price: 120,
  },
  {
    intervention: "Očkovanie nad rámec poistenia na žiadosť pacientky",
    price: 10,
  },
  {
    intervention: "Posun menses na žiadosť pacientky bez zdravotnej indikácie",
    price: 10,
  },
  {
    intervention: "Chirurgické výkony pre iné ako terapeutické dôvody",
    price: 34,
  },
  {
    intervention:
      "Vystavenie žiadosti na interupciu vrátane vyšetrenia, odberov a poučenia",
    price: 40,
  },
  {
    intervention:
      "Vystavenie žiadosti na sterilizáciu vrátane odberov a poučenia pacientky",
    price: 25,
  },
  {
    intervention:
      "Prevzatie pacientky do starostlivosti (mimo prideleného spádu)",
    price: 25,
  },
  {
    intervention:
      "Odregistrovanie pacientky z kapitácie pred uplynutím zákonného 6-mesačného limitu",
    price: 15,
  },
  {
    intervention: "Vystavenie kópie lekárskej správy na žiadosť pacientky",
    price: 5,
  },
  {
    intervention: "Zavedenie vnútromaternicového telieska",
    price: 40,
  },
  {
    intervention:
      "Odstránenie vnútromaternicového telieska na žiadosť pacientky",
    price: 10,
  },
  {
    intervention:
      "Výpis zo zdravotnej dokumentácie na komerčné účely s gynekologickým záverom (Z02.7)",
    price: 5,
  },
  {
    intervention:
      "Konzultácia pri liečbe sterility - vstupná konzultácia a vyšetrenie",
    price: 20,
  },
  {
    intervention: "Konzultácia pri liečbe sterility (za každých 5 minút)",
    price: 3,
  },
  {
    intervention: "Folikulometria",
    price: 5,
  },
  {
    intervention: "Intrauterinná inseminácia",
    price: 50,
  },
  {
    intervention: "Vyšetrenie spermiogramu (výkon nehradený poskytovateľovi)",
    price: 15,
  },
  {
    intervention:
      "Manažment páru v ambulancii pre liečbu sterility počas 6 mesiacov/cyklov",
    price: 65,
  },
]
