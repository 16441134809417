import { Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet"
import "./App.less"
import configData from "../../config.json"
import * as route from "../../routes"
import PageWrapper from "../Wrappers/PageWrapper"
import Header from "../Header"
import Login from "../../pages/Login"
import Admin from "../../pages/Admin"
import AboutUs from "../../pages/AboutUs"
import OurTeam from "../../pages/OurTeam"
import Ambulance from "../../pages/Ambulance"
import Gallery from "../../pages/Gallery"
import PriceList from "../../pages/PriceList"
import Instructions from "../../pages/Instructions"
import Calculator from "../../pages/Calculator"
import Contacts from "../../pages/Contacts"
import Footer from "../Footer"

const App = () => (
  <>
    <Header />
    <PageWrapper>
      <Switch>
        <Route path={route.LOGIN}>
          <Helmet>
            <title>{`${configData.title} | Prihlásenie`}</title>
          </Helmet>
          <Login />
        </Route>
        <Route path={route.ADMIN}>
          <Helmet>
            <title>{`${configData.title} | Admin`}</title>
          </Helmet>
          <Admin />
        </Route>
        <Route path={route.ABOUT_US} exact>
          <Helmet>
            <title>{configData.title}</title>
          </Helmet>
          <AboutUs />
        </Route>
        <Route path={route.TEAM}>
          <Helmet>
            <title>{`${configData.title} | Náš tím`}</title>
          </Helmet>
          <OurTeam />
        </Route>
        <Route path={route.AMBULANCE}>
          <Helmet>
            <title>{`${configData.title} | Ambulancia`}</title>
          </Helmet>
          <Ambulance />
        </Route>
        <Route path={route.GALLERY}>
          <Helmet>
            <title>{`${configData.title} | Galéria`}</title>
          </Helmet>
          <Gallery />
        </Route>
        <Route path={route.PRICE_LIST}>
          <Helmet>
            <title>{`${configData.title} | Cenník`}</title>
          </Helmet>
          <PriceList />
        </Route>
        <Route path={route.INSTRUCTIONS}>
          <Helmet>
            <title>{`${configData.title} | Poučenia`}</title>
          </Helmet>
          <Instructions />
        </Route>
        <Route path={route.CALCULATOR}>
          <Helmet>
            <title>{`${configData.title} | Kalkulačka`}</title>
          </Helmet>
          <Calculator />
        </Route>
        <Route path={route.CONTACT}>
          <Helmet>
            <title>{`${configData.title} | Kontakt`}</title>
          </Helmet>
          <Contacts />
        </Route>
        <Route path="*">
          <Helmet>
            <title>{configData.title}</title>
          </Helmet>
          <AboutUs />
        </Route>
      </Switch>
    </PageWrapper>
    <Footer />
  </>
)

export default App
