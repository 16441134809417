import HeadLine from "../../components/HeadLine/Headline"
import LoginForm from "../../components/LoginForm"

const Login = () => {
  return (
    <>
      <HeadLine>Správcovská sekcia</HeadLine>
      <LoginForm />
    </>
  )
}

export default Login
