import { Image, Tabs } from "antd"
import { StyledGallery } from "./StyledComponents"
import {
  twoDimensional,
  threeDimensional,
  fourDimensional,
  ambulance,
} from "./images"
import StyledTabs from "../../components/StyledTabs"

const { TabPane } = Tabs

const maskLabel = "Zobraziť"

const mappedTwoDimensionalImages = twoDimensional.map((image) => (
  <Image
    key={image.key}
    src={image.mini}
    alt={image.alt}
    width={200}
    preview={{
      src: image.image,
      mask: maskLabel,
    }}
  />
))

const mappedThreeDimensionalImages = threeDimensional.map((image) => (
  <Image
    key={image.key}
    src={image.image}
    alt={image.alt}
    width={200}
    preview={{
      mask: maskLabel,
    }}
  />
))

const mappedFourDimensionalImages = fourDimensional.map((image) => (
  <Image
    key={image.key}
    src={image.mini}
    alt={image.alt}
    width={200}
    preview={{
      src: image.image,
      mask: maskLabel,
    }}
  />
))

const mappedAmbulanceImages = ambulance.map((image) => (
  <Image
    key={image.key}
    src={image.mini}
    alt={image.alt}
    width={200}
    preview={{
      src: image.image,
      mask: maskLabel,
    }}
  />
))

const Gallery = () => (
  <>
    <StyledTabs type="card" centered>
      <TabPane tab="2D" key="1">
        <StyledGallery>
          <Image.PreviewGroup>{mappedTwoDimensionalImages}</Image.PreviewGroup>
        </StyledGallery>
      </TabPane>
      <TabPane tab="3D" key="2">
        <StyledGallery>
          <Image.PreviewGroup>
            {mappedThreeDimensionalImages}
          </Image.PreviewGroup>
        </StyledGallery>
      </TabPane>
      <TabPane tab="3D/4D" key="3">
        <StyledGallery>
          <Image.PreviewGroup>{mappedFourDimensionalImages}</Image.PreviewGroup>
        </StyledGallery>
      </TabPane>
      <TabPane tab="Naša ordinácia" key="4">
        <StyledGallery>
          <Image.PreviewGroup>{mappedAmbulanceImages}</Image.PreviewGroup>
        </StyledGallery>
      </TabPane>
    </StyledTabs>
  </>
)

export default Gallery
