export const ABOUT_US = "/"
export const ADMIN = "/admin"
export const AMBULANCE = "/ambulancia"
export const CALCULATOR = "/kalkulacka"
export const CONTACT = "/kontakt"
export const GALLERY = "/galeria"
export const INSTRUCTIONS = "/poucenia"
export const LOGIN = "/prihlasenie"
export const TEAM = "/pracovnici"
export const PRICE_LIST = "/cennik"
