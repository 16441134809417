import PersonalCard from "../../components/PersonalCard/PersonalCard"
import HeadLine from "../../components/HeadLine/Headline"
import { teammates } from "./team"
import styled from "styled-components"

let key1 = 0
let key2 = 20

const StyledAside = styled.aside`
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
  justify-content: space-around;
  :last-child {
    margin-bottom: 100px;
  }
`

const team = teammates.map((person) => {
  const positions = person.positions.map((position) => {
    key1 = key1 + 1
    return <span key={key1}>{position}</span>
  })

  const certificates = person.certificates.map((certificate) => {
    key2 = key2 + 1
    return <span key={key2}>{certificate}</span>
  })

  return (
    <PersonalCard
      key={person.surname}
      photo={person.photo}
      altText={person.altText}
      titleBeforeName={person.titleBeforeName}
      name={person.name}
      surname={person.surname}
      titleAfterName={person.titleAfterName}
      positions={positions}
      education={person.education}
      certificates={certificates}
    />
  )
})

const OurTeam = () => (
  <>
    <HeadLine>Náš tím</HeadLine>
    <StyledAside>{team}</StyledAside>
  </>
)

export default OurTeam
