import { Form, Input, Button, Select, DatePicker, message } from "antd"
import emailjs from "emailjs-com"

const PregnantUltrasound = () => {
  const [form] = Form.useForm()
  const dateParams = { year: "numeric", month: "long", day: "numeric" }

  const transformToSlovakDateFormat = (date) =>
    date.toLocaleDateString("sk-SK", dateParams)

  function sendEmail(e) {
    emailjs
      .send(
        "autoContactCegys",
        "template_cegys",
        {
          name: e.name,
          surname: e.surname,
          email: e.email,
          phone: e.phone,
          examination: e.examination,
          lastPeriod: transformToSlovakDateFormat(new Date(e.lastPeriod)),
          info: e.info,
        },
        "user_eqClql9gUwp6WZtHAyodx"
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log(e.name)
          message.success("Vaša žiadosť o vyšetrenie bola odoslaná!")
          form.resetFields()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={sendEmail}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Titul">
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Meno"
        name="name"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoje meno.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Priezvisko"
        name="surname"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoje priezvisko.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "Prosím zadajte svoj e-mail.",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item label="Telefón" name="phone">
        <Input type="tel" />
      </Form.Item>
      <Form.Item
        label="Vyšetrenie"
        name="examination"
        rules={[
          {
            required: true,
            message: "Zabudli ste vybrať typ vyšetrenia.",
          },
        ]}
      >
        <Select>
          <Select.Option value="I. trimester - ultrazvuk v tehotnosti">
            I. trimester
          </Select.Option>
          <Select.Option value="Vyšetrenie NT - ultrazvuk v tehotnosti">
            Vyšetrenie NT
          </Select.Option>
          <Select.Option value="II. trimester - ultrazvuk v tehotnosti">
            II. trimester
          </Select.Option>
          <Select.Option value="III. trimester/morfologický ultrazvuk - ultrazvuk v tehotnosti">
            III. trimester/morfologický ultrazvuk
          </Select.Option>
          <Select.Option value="III. trimester - ultrazvuk v tehotnosti">
            III. trimester
          </Select.Option>
          <Select.Option value="Prietoky/flowmetria - ultrazvuk v tehotnosti">
            Prietoky/flowmetria
          </Select.Option>
          <Select.Option value="Iné">Iné</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Posledná menštruácia"
        name="lastPeriod"
        rules={[
          {
            required: false,
            message: "Zabudli ste zadať dátum poslednej menštruácie.",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item label="Pripomienky:" name="info">
        <Input type="text" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Odoslať
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PregnantUltrasound
