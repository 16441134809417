import styled from "styled-components"

export const GoogleMap = styled.iframe`
  width: 600px;
  height: 400px;
  margin: 1.5%;
  @media only screen and (max-width: 1225px) {
    width: 94%;
    margin: 10px 3%;
  }
`

export const SliderWrapper = styled.div`
  @media only screen and (max-width: 1225px) {
    width: 94%;
    height: 410px;
    margin: 0px 3% 10px 3%;
  }
`
export const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1225px) {
    display: block;
  }
`
