import "antd/dist/antd.css"
import { Modal, Tabs } from "antd"
import StyledTabs from "../StyledTabs"
import Examination from "../Forms/Examination"
import PregnantUltrasound from "../Forms/PregnantUltrasound"
import Ultrasound from "../Forms/Ultrasound"

const { TabPane } = Tabs

const DefaultModal = ({ visible, onCancel, onCreate }) => {
  return (
    <Modal
      visible={visible}
      title="Objednajte sa na vyšetrenie"
      okText="Odoslať"
      onCancel={onCancel}
      onOk={onCreate}
      width={1000}
      footer={null}
    >
      <StyledTabs style={{ margin: "0", fontSize: "10px" }} tabPosition="top">
        <TabPane tab="Urogynekologické vyšetrenie" key="1">
          <Examination />
        </TabPane>
        <TabPane tab="Gynekologický ultrazvuk" key="2">
          <Ultrasound />
        </TabPane>
        <TabPane tab="Ultrazvuk v tehotnosti" key="3">
          <PregnantUltrasound />
        </TabPane>
      </StyledTabs>
    </Modal>
  )
}

export default DefaultModal
