import styled from "styled-components"
import { Tabs } from "antd"

const StyledTabs = styled(Tabs)`
  margin: 10px 0px;
  .ant-tabs-tab {
    font-size: 20px;
  }
  .ant-tabs-tab-btn {
    padding: 10px 35px;
  }
  @media only screen and (max-width: 600px) {
    .ant-tabs-tab {
      font-size: 15px;
    }
    .ant-tabs-tab-btn {
      padding: 5px 10px;
    }
  }
`

export default StyledTabs
