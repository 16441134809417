import React from "react"
import "antd/dist/antd.less"
import { Form, Input, Button, message } from "antd"
import emailjs from "emailjs-com"
import styled from "styled-components"

const layout = {
  // labelCol: {
  //   span: 20,
  // },
  // wrapperCol: {
  //   span: 10,
  // },
}
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
}

const StyledContactForm = styled(Form)`
  button {
    height: 50px;
    width: 200px;
    font-size: 20px;
    margin: -15px 34% 0px 34%;
  }
  @media only screen and (max-width: 1200px) {
    width: 94%;
    margin: 10px 3%;
  }
`

const ContactForm = () => {
  const [form] = Form.useForm()

  function sendEmail(e) {
    emailjs
      .send(
        "autoContactCegys",
        "template_ap2zyx7",
        {
          name: e.name,
          surname: e.surname,
          email: e.email,
          phone: e.phone,
          info: e.info,
        },
        "user_eqClql9gUwp6WZtHAyodx"
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log(e.name)
          message.success(
            "Vaša otázka bola úspešne odoslaná! Odpovieme čoskoro."
          )
          form.resetFields()
        },
        (error) => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  return (
    <StyledContactForm
      form={form}
      style={{ marginTop: "25px" }}
      {...layout}
      name="nest-messages"
      onFinish={sendEmail}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="name"
        label="Meno"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="surname"
        label="Priezvisko"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Telefón"
        rules={[
          {
            type: "string",
          },
        ]}
      >
        <Input maxLength={10} />
      </Form.Item>
      <Form.Item
        name="info"
        label="Vaša otázka/požiadavka"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
        <Button type="primary" htmlType="submit">
          Odoslať formulár
        </Button>
      </Form.Item>
    </StyledContactForm>
  )
}

export default ContactForm
