// Gynecology and obstetrics
import ambulance1 from "../../assets/images/ambulance3D/640/recepcia01_640.jpg"
import ambulance2 from "../../assets/images/ambulance3D/640/cakaren01_640.jpg"
import ambulance3 from "../../assets/images/ambulance3D/640/ordinacia_640.jpg"
import ambulance4 from "../../assets/images/ambulance3D/640/ordinacia00_640.jpg"
import ambulance5 from "../../assets/images/ambulance3D/640/ordinacia02_640.jpg"

// 2D Ultrasound
import img2D1 from "../../assets/images/images2D/640/01a_640.jpg"
import img2D2 from "../../assets/images/images2D/640/01b_640.jpg"
import img2D3 from "../../assets/images/images2D/640/01c_640.jpg"
import img2D4 from "../../assets/images/images2D/640/01d_640.jpg"
import img2D5 from "../../assets/images/images2D/640/01e_640.jpg"

// 3D Ultrasound
import img3D1 from "../../assets/images/images3D/obr10.jpg"
import img3D2 from "../../assets/images/images3D/obr12.jpg"
import img3D3 from "../../assets/images/images3D/obr6.jpg"
import img3D4 from "../../assets/images/images3D/obr8.jpg"
import img3D5 from "../../assets/images/images3D/obr5.jpg"

// Uro-gynecology
import device from "../../assets/images/urodynamicky_pristroj.png"
import report from "../../assets/images/urodynamicky_zaznam.png"

export const uroGynecologyPictures = [
  {
    key: "1",
    image: device,
    alt: "Fotka urodynamického prístorja",
  },
  {
    key: "2",
    image: report,
    alt: "Urodynamický záznam",
  },
] 

export const ambulancePictures = [
  {
    key: "1",
    image: ambulance1,
    alt: "Fotka recepcie",
  },
  {
    key: "2",
    image: ambulance2,
    alt: "Fotka čakárne",
  },
  {
    key: "3",
    image: ambulance3,
    alt: "Fotka ordinácie 1",
  },
  {
    key: "4",
    image: ambulance4,
    alt: "Fotka ordinácie 2",
  },
  {
    key: "5",
    image: ambulance5,
    alt: "Fotka ordinácie 3",
  },
]

export const ultrasoundPictures = [
  {
    key: "1",
    image: img2D1,
    alt: "Sonografia 1",
  },
  {
    key: "2",
    image: img2D2,
    alt: "Sonografia 2",
  },
  {
    key: "3",
    image: img2D3,
    alt: "Sonografia 3",
  },
  {
    key: "4",
    image: img2D4,
    alt: "Sonografia 4",
  },
  {
    key: "5",
    image: img2D5,
    alt: "Sonografia 5",
  },
]

export const ultrasound3dPictures = [
  {
    key: "1",
    image: img3D1,
    alt: "3D ultrazvuk 1",
  },
  {
    key: "2",
    image: img3D2,
    alt: "3D ultrazvuk 2",
  },
  {
    key: "3",
    image: img3D3,
    alt: "3D ultrazvuk 3",
  },
  {
    key: "4",
    image: img3D4,
    alt: "3D ultrazvuk 4",
  },
  {
    key: "5",
    image: img3D5,
    alt: "3D ultrazvuk 5",
  },
]
