import building1 from "../../assets/images/bulding/1024/20211005_165927_1024.jpg"
import building2 from "../../assets/images/bulding/1024/20211005_170037_1024.jpg"
import building3 from "../../assets/images/bulding/1024/20211005_170012_1024.jpg"
import building4 from "../../assets/images/bulding/1024/20211005_170243_1024.jpg"
import building5 from "../../assets/images/bulding/1024/20211005_170822_1024.jpg"
import building6 from "../../assets/images/bulding/1024/20211005_170222_1024.jpg"

export const pictures = [
  {
    key: "1",
    image: building1,
    alt: "PIC1",
  },
  {
    key: "2",
    image: building2,
    alt: "PIC1",
  },
  {
    key: "3",
    image: building3,
    alt: "PIC1",
  },
  {
    key: "4",
    image: building4,
    alt: "PIC1",
  },
  {
    key: "5",
    image: building5,
    alt: "PIC1",
  },
  {
    key: "6",
    image: building6,
    alt: "PIC1",
  },
]