import HeadLine from "../../components/HeadLine/Headline"
import { Ul, Li, Link } from "./StyledComponents"
import { instructionsList } from "./instructionsList"

const mappedInstructionsList = instructionsList.map((instruction) => (
  <Li key={instruction.key}>
    <Link href={instruction.file} rel="noreferrer" target="_blank">
      {instruction.name}
    </Link>
  </Li>
))

const Instructions = () => (
  <>
    <HeadLine>Poučenia</HeadLine>
    <Ul>{mappedInstructionsList}</Ul>
  </>
)

export default Instructions
