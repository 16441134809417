import { useState } from "react"
import {
  columns,
  smallColumns,
  mappedScheduleList,
  mappedSmallScheduleList,
  tableHeaderHandler,
} from "./tableValues"
import { pictures } from "./pictures"
import icon from "../../assets/images/info_icon.png"
import StyledTable from "../../components/StyledTable"
import { introductionCards } from "./introductionCards"
import Slider from "../../components/Slider"
import IconWithText from "./IconWithText"
import Modal from "../../components/Modal"
import configData from "../../config.json"
import {
  StyledButton,
  HeadlineWrapper,
  HeadlineBox,
  InfoWrapper,
  ImageWrapper,
  Line,
} from "./StyledComponents"
import Paragraph from "../../components/Paragraph"
import { texts } from "./texts"
import CloseBar from "./CloseBar"

const AboutUs = () => {
  const [visible, setVisible] = useState(false)
  const mappedIntroductionCards = introductionCards.map((card) => (
    <IconWithText
      key={card.key}
      icon={card.icon}
      altText={card.altText}
      highStartText={card.highStartText}
      text={card.text}
      highEndText={card.highEndText}
    />
  ))
  const mappedTexts = texts.map((text) => (
    <Paragraph isOnAboutUs={true}>{text}</Paragraph>
  ))

  const [isInfoDisplayed, setIsInfoDisplay] = useState(true)

  const infoDisplayHandler = () => {
    setIsInfoDisplay(false)
  }

  return (
    <>
      <HeadlineWrapper>
        <HeadlineBox>
          <Slider pictures={pictures} height="400px" dots={false} />
        </HeadlineBox>
        <HeadlineBox>
          <div className="largeTable">
            <StyledTable
              columns={columns}
              dataSource={mappedScheduleList}
              title={tableHeaderHandler}
              showHeader={false}
            />
          </div>
          <div className="smallTable">
            <StyledTable
              columns={smallColumns}
              dataSource={mappedSmallScheduleList}
              title={tableHeaderHandler}
              showHeader={false}
            />
          </div>
          <StyledButton type="primary" onClick={() => setVisible(true)}>
            Objednať sa
          </StyledButton>
          <Modal visible={visible} onCancel={() => setVisible(false)} />
        </HeadlineBox>
      </HeadlineWrapper>
      {isInfoDisplayed && (
        <InfoWrapper>
          <CloseBar
            headText="AKTUALITY"
            infoDisplayHandler={infoDisplayHandler}
          />
          <div>
            <ImageWrapper>
              <img src={icon} alt="ikonka" width="96px" />
            </ImageWrapper>
            <span>
              Dovolujeme si Vás informovať že <strong>od dňa 1.1.2023</strong> vstupuje do platnosti nový cenník našich služieb.
              <Line />
              <strong>Od 1.6.2022 poskytujeme</strong> okrem štandardných
              možností objednania na urogynekologické vyšetrenie
              (obvod-MUDr.Kopčan) aj{" "}
              <strong>objednanie na konkrétny čas</strong> za poplatok. Ak si
              želáte objednať sa takýmto spôsobom možete tak urobiť{" "}
              <a
                href={configData.branoKopcanOrderLink}
                target="_blank"
                rel="noreferrer"
              >
                TU.
              </a>
              <Line />
              Opakovane sa nás pacientky pýtajú, či berieme aj nové pacientky do
              starostlivosti, do tzv. kapitácie. Odpoveď je{" "}
              <strong>áno, berieme.</strong> Dávame do pozornosti pacientok
              možnosť objednať sa <a onClick={() => setVisible(true)}>TU.</a>{" "}
              Dôležité je uviesť druh požadovaného vyšetrenia a ďalšie potrebné
              údaje pre určenie termínu Vášho vyšetrenia. Najskôr, ako to bude
              možné, na mail odpovieme.
            </span>
          </div>
        </InfoWrapper>
      )}
      {mappedTexts}
      {mappedIntroductionCards}
    </>
  )
}

export default AboutUs
