import { Carousel, Image } from "antd"
import styled from "styled-components"

const StyledCarousel = styled(Carousel)`
  height: 410px;
  width: 530px;
  margin: 3% 3%;
  img {
    margin: 5px auto;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  @media only screen and (max-width: 1200px) {
    width: 94%;
    margin: 3% 3%;
  }
`

const Slider = (props) => {
  let key = 0
  const isImageClickable = props.isImageClickable
  const mappedPictures = props.pictures.map((picture) => (
    <div key={key + 1}>
      {isImageClickable ? (
        <Image
          alt={picture.alt}
          src={picture.image}
          height={props.height}
          preview={{
            mask: "Zobraziť",
          }}
        />
      ) : (
        <img alt={picture.alt} src={picture.image} height={props.height} />
      )}
    </div>
  ))
  return (
    <StyledCarousel effect="fade" dots={props.dots} autoplay>
      {mappedPictures}
    </StyledCarousel>
  )
}

export default Slider
