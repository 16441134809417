import styled from "styled-components"
import { Button } from "antd"

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    flex-flow: column;
    form {
      order: 2;
    }
  }
`

export const StyledButton = styled(Button)`
  height: 40px;
  width: 100px;
  margin-left: 20px;
  @media only screen and (max-width: 600px) {
    width: 94%;
    height: 40px;
    margin: 5px 3% 0 3%;
  }
`
