import styled from "styled-components"

export const Link = styled.a`
  color: black;

  &:hover {
    color: deeppink;
  }
`
export const Li = styled.li`
  list-style-type: none;
  font-size: 25px;
`

export const Ul = styled.ul`
  @media only screen and (max-width: 1320px) {
    margin-left: 3%;
  }
`
