import { StyledNavLink, StyledUl } from "./StyledComponents"
import { headerOptions } from "./headerOptions"

const mappedHeaderOptions = headerOptions.map((opt) => (
  <li key={opt.key}>
    <StyledNavLink to={opt.navigateTo}>{opt.name}</StyledNavLink>
  </li>
))

const NormalScreenMenu = () => {
  return <StyledUl>{mappedHeaderOptions}</StyledUl>
}

export default NormalScreenMenu
