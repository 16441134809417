import HeadLine from "../../components/HeadLine/Headline"
import StyledTable from "../../components/StyledTable"
import { mappedPriceList, columns } from "./tableValues"

const PriceList = () => (
  <>
    <HeadLine>Cenník výkonov</HeadLine>
    <StyledTable
      columns={columns}
      dataSource={mappedPriceList}
      isQuarter={true}
    />
  </>
)

export default PriceList
