import Slider from "../../components/Slider"
import { pictures } from "./pictures"
import HeadLine from "../../components/HeadLine/Headline"
import ContactForm from "./ContactForm"
import InsuranceCompanies from "../../components/InsuranceCompanies"
import { GoogleMap, SliderWrapper, HeadlineWrapper } from "./StyledComponents"

const Contacts = () => (
  <>
    <HeadLine>Kde nás nájdete</HeadLine>
    <HeadlineWrapper>
      <GoogleMap
        title="Cegys na mape"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.6832246390723!2d18.028458351239223!3d48.8833150791882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714a1820e426cf1%3A0x562d00f35f220beb!2zTGVnaW9uw6Fyc2thIDMxLCA5MTEgMDEgVHJlbsSNw61u!5e0!3m2!1ssk!2ssk!4v1631601383255!5m2!1ssk!2ssk"
        loading="lazy"
      />
      <SliderWrapper>
        <Slider isImageClickable={true} pictures={pictures} height="400px" />
      </SliderWrapper>
    </HeadlineWrapper>
    <HeadlineWrapper>
      <ContactForm />
      <InsuranceCompanies />
    </HeadlineWrapper>
  </>
)

export default Contacts
