import React from "react"
import { NavLink } from "react-router-dom"
import logo1 from "../../assets/images/title_logo.png"
import logoText from "../../assets/images/cegys_text.png"
import {
  HeaderContainer,
  StyledHeader,
  StyledNormalNav,
  StyledSmallNav,
} from "./StyledComponents"
import SmallScreenMenu from "./SmallScreenMenu"
import NormalScreenMenu from "./NormalScreenMenu"

const Header = () => {
  return (
    <HeaderContainer>
      <StyledHeader>
        <NavLink to="/">
          <img src={logo1} alt="Cegys logo" width="50" />
          <img src={logoText} alt="Cegys text" width="125" />
        </NavLink>
        <StyledNormalNav>
          <NormalScreenMenu />
        </StyledNormalNav>
        <StyledSmallNav>
          <SmallScreenMenu />
        </StyledSmallNav>
      </StyledHeader>
    </HeaderContainer>
  )
}

export default Header
