import styled from "styled-components"
import { NavLink } from "react-router-dom"
import configData from "../../config.json"
import { CaretDownOutlined } from "@ant-design/icons"

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: #403e44;
`

export const StyledHeader = styled.header`
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img:first-of-type {
    margin-left: 20px;
  }
`

export const StyledNormalNav = styled.nav`
  @media (max-width: 1096px) {
    display: none;
  }
`
export const StyledSmallNav = styled.nav`
  display: none;
  @media (max-width: 1096px) {
    display: block;
  }
`

export const StyledIcon = styled(CaretDownOutlined)`
  color: deeppink;
  font-size: 50px !important;
`

export const StyledNavLink = styled(NavLink)`
  opacity: 0.8;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  text-decoration: none;
  color: white;
  padding: 29px 30px;
  &.active,
  &:hover {
    color: white;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    cursor: pointer;
  }
  &.active {
    color: ${configData.color};
  }
  @media (max-width: 1096px) {
    padding: 10px;
  }
`

export const StyledUl = styled.ul`
  display: inline-flex;
  list-style-type: none;
  margin: 0 auto;
`
export const StyledMobileUl = styled.ul`
  display: block;
  list-style-type: none;
  z-index: 1;
  position: fixed;
  background-color: #403e44;
  width: 100%;
  font-size: 30px;
  left: 0;
  text-align: center;
  li {
    margin: 15px;
  }
`
