import styled from "styled-components"

const StyledParagraph = styled.p`
  margin-top: ${(props) => (props.isOnAboutUs && "15px")};
  margin-left: ${(props) => (props.isOnAboutUs && "1.5%")};
  color: ${(props) => (props.isErrorMessage && "red")};
  margin-top: ${(props) => (props.isErrorMessage && "10px")};
`

const Paragraph = (props) => (
  <StyledParagraph isOnAboutUs={props.isOnAboutUs} isErrorMessage={props.isErrorMessage}>
    {props.children}
  </StyledParagraph>
)

export default Paragraph
