import React from "react"

import point from "../../assets/icons/point.png"
import phone from "../../assets/icons/phone.png"
import classes from "./Footer.module.css"
import InsuranceCompanies from "../InsuranceCompanies"

const Footer = () => {
  return (
    <div className={classes.footer_wrapper}>
      <footer className={classes.footer}>
        <div className={classes.container}>
          <div>
            <div className={classes.inline}>
              <img src={point} alt="lokácia" className={classes.icon} />
              <span>CeGyS, s.r.o.</span>
              <span>Legionárska 31</span>
              <span>911 01, Trenčín</span>
            </div>
          </div>
          <div>
            <div className={`${classes.inline}`}>
              <img src={phone} alt="telefón" className={classes.icon} />
              <span>Mobil: 0948 009 005</span>
              <span>Tel.: 032 / 640 13 99</span>
              <span>
                E-mail: <a href="mailto:cegys@cegys.sk">cegys@cegys.sk</a>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.add}>
          {/* <div className={`${classes.banner} ${classes.hidden}`}>
            <p>Partner 2:</p>
            <p>
              <a href="https://www.google.com" rel="noreferrer" target="_blank">
                GOOGLE
              </a>
            </p>
          </div>
          <div className={`${classes.banner} ${classes.hidden}`}>
            <p>Partner 2:</p>
            <p>
              <a href="https://www.google.com" rel="noreferrer" target="_blank">
                GOOGLE
              </a>
            </p>
          </div> */}
          <InsuranceCompanies isFooter={true} />
        </div>
        <div className={classes.footer_text}>
          <p>
            Copyright © 2021 <a href="https://www.cegys.sk">cegys.sk</a>. Všetky
            práva vyhradené.
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
