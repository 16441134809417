import styled from "styled-components"
import { Table } from "antd"

const StyledAntTable = styled(Table)`
  width: 100%;
  margin: 25px auto;
  text-align: left;
  border-collapse: collapse;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid deeppink;
  thead th,
  .ant-table-title {
    font-family: "Playfair Display";
    font-size: large;
    font-weight: 600;
    color: white;
    background-color: #403e44;
  }
  th,
  td {
    padding: 15px 10px;
  }
  tbody {
    font-size: 1.2em;
  }
  tbody tr:nth-child(even) {
    background-color: #403e4421;
    color: deeppink;
  }
  @media only screen and (max-width: 1320px) {
    width: auto;
    margin: 25px 3%;
    th,
    td {
      padding: 10px 5px;
    }
  }
`

const StyledTable = (props) => (
  <StyledAntTable
    pagination={false}
    columns={props.columns}
    dataSource={props.dataSource}
    title={props.title}
    showHeader={props.showHeader}
    style={{width: props.isQuarter ? "90%" : null}}
  />
)

export default StyledTable
