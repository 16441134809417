import { useState } from "react"
import { Select } from "antd"
import HeadLine from "../../components/HeadLine/Headline"
import StyledTable from "../../components/StyledTable"
import Period from "./Period"
import Ultrasound from "./Ultrasound"
import styled from "styled-components"
import { acts, daysToAdd } from "./calcValues"
import { FormWrapper } from "./StyledComponents"
import { columns } from "./tableValues"
import Paragraph from "../../components/Paragraph"

const StyledSelect = styled(Select)`
  width: 435px;
  font-size: 25px;
  @media only screen and (max-width: 600px) {
    width: 94%;
    margin: 5px 3%;
  }
`

const Calculator = () => {
  const [calculator, setCalculator] = useState("period")
  const [datesTable, setDatesTable] = useState(acts)
  const [emptyFieldMessage, setEmptyFieldMessage] = useState(false)
  const [wrongValueMessage, setWrongValueMessage] = useState(false)

  const emptyFieldErrorMessage = "Nevyplnili ste všetky polia."
  const wrongValueErrorMessage = "V poliach týždeň a deň sú povolené iba čísla."

  const calculatorSelectHandler = (value) => {
    setCalculator(value)
  }

  const dateParams = { year: "numeric", month: "long", day: "numeric" }

  const transformToSlovakDateFormat = (date) =>
    date.toLocaleDateString("sk-SK", dateParams)

  const datesRangeCreator = (startDate, endDate) => `${startDate} - ${endDate}`

  const addDays = (date, days) => {
    const newDate = new Date(date.setDate(date.getDate() + days))
    return newDate
  }

  const calculateDatesHandler = (e) => {
    e.preventDefault()
    const selectedDate = e.target[0].defaultValue
    const date = new Date(selectedDate)
    console.log(selectedDate)
    if (!selectedDate) {
      setEmptyFieldMessage(true)
      return
    } else {
      setEmptyFieldMessage(false)
    }
    let formattedDate = new Date(date)
    if (calculator === "ultrasound") {
      const week = e.target[1].defaultValue
      const day = e.target[2].defaultValue
      if (!week || !day) {
        setEmptyFieldMessage(true)
        return
      } else if (isNaN(day) && isNaN(week)) {
        setWrongValueMessage(true)
        return
      } else {
        setWrongValueMessage(false)
        setEmptyFieldMessage(false)
      }
      formattedDate = addDays(date, -week * 7 - day)
    }
    const datesArray = daysToAdd.map((days) => {
      const increasedDate = addDays(formattedDate, days)
      const slovakDate = transformToSlovakDateFormat(increasedDate)
      formattedDate = addDays(formattedDate, -days)
      return slovakDate
    })

    let i = 0

    const remappedDatesTable = acts.map((act) => {
      if (i === 0) {
        i = i + 1
        return {
          ...act,
          datesRange: datesArray[0],
        }
      }
      i = i + 2
      return {
        ...act,
        datesRange: datesRangeCreator(datesArray[i - 2], datesArray[i - 1]),
      }
    })

    setDatesTable(remappedDatesTable)
  }

  return (
    <>
      <HeadLine>Tehotenská kalkulačka</HeadLine>
      <FormWrapper>
        <form onSubmit={calculateDatesHandler}>
          {calculator === "period" ? <Period /> : null}
          {calculator === "ultrasound" ? <Ultrasound /> : null}
        </form>
        <StyledSelect
          size="large"
          onChange={calculatorSelectHandler}
          defaultValue="period"
        >
          <Select.Option value="period" selected>
            Podľa dátumu poslednej menštruácie
          </Select.Option>
          <Select.Option value="ultrasound">
            Podľa dátumu ultrazvuku v I. trimestri
          </Select.Option>
        </StyledSelect>
      </FormWrapper>
      {emptyFieldMessage && (
        <Paragraph isErrorMessage={true}>{emptyFieldErrorMessage}</Paragraph>
      )}
      {wrongValueMessage && (
        <Paragraph isErrorMessage={true}>{wrongValueErrorMessage}</Paragraph>
      )}
      <StyledTable columns={columns} dataSource={datesTable} />
    </>
  )
}

export default Calculator
