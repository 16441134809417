import styled from "styled-components"
import { Collapse } from "antd"

const { Panel } = Collapse

export const CollapseWrapper = styled.div`
  .ant-collapse {
    background-color: transparent;
  }
`

export const StyledPanel = styled(Panel)`
  .ant-collapse-arrow {
    color: deeppink;
    svg {
      transform: scale(2.5);
    }
  }
  .ant-collapse-header {
    font-size: 27px;
  }
  .ant-collapse-content-box {
    font-size: 15px;
  }
`

export const TextWrapper = styled.div`
  width: 70%;
`

export const AmbulanceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1280px) {
    div:first-child {
      width: 100%;
    } 
    div:nth-child(2) {
      display: none;
    }
  }
`
