import styled from "styled-components"
import { CloseOutlined } from "@ant-design/icons"

const Wrapper = styled.div`
   {
    height: 50px;
    background-color: #403e4421;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  span {
    float: right;
    margin: 15px;
    text-size-adjust: auto;
  }
`

const Headline = styled.h4`
  margin: 10px 20px;
  font-weight: bold;
`

const CloseBar = (props) => (
  <Wrapper>
    <Headline>{props.headText}</Headline>
    <CloseOutlined onClick={props.infoDisplayHandler} />
  </Wrapper>
)

export default CloseBar
