import classes from "./PageWrapper.module.css"

const PageWrapper = (props) => {
 const rejectRightClick = (e) => e.preventDefault()
  return (
    <div onContextMenu={rejectRightClick} className={classes.container}>
      <div className={classes.wrapper}>{props.children}</div>
    </div>
  )
}

export default PageWrapper
