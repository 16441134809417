import { DatePicker } from "antd"
import styled from "styled-components"

const StyledDatePicker = styled(DatePicker)`
  @media only screen and (max-width: 600px) {
    width: 94%;
    margin: 5px 3%;
  }
`

const DateSelector = (props) => (
  <StyledDatePicker
    placeholder="Zvoľte dátum"
    size="large"
    type="date"
    name={props.name}
    id={props.id}
  />
)

export default DateSelector
